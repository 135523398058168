import { UserSettingsConfig } from "@/classes/UserSettingsManagement";
import { FilterParams } from "@/store/modules/userSettings";

export const USER_SETTINGS_VERSION = "1.6.0";

export const defaultFilterValues: FilterParams = {
  advertiser: ["All"],
  agency: ["All"],
  businessGoal: ["All"],
  campaign: ["All"],
  campaignKpi: ["All"],
  campaignBudget: ["All"],
  campaignId: ["All"],
  channel: ["All"],
  dateRange: { type: "preset", value: { preset: "last12Months" } },
  industry: ["All"],
};

export const defaultSelectedFilters = ["dateRange", "industry", "businessGoal", "campaignKpi", "channel"];

export default <UserSettingsConfig>{
  dashboards: [
    {
      icon: "global-overview",
      title: "Global Overview",
      layout: [
        {
          grid: {
            h: 4,
            i: "2",
            w: 12,
            x: 0,
            y: 0,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Summary",
            family: "other",
            widget: "SummaryWidget",
            settings: {
              metrics: [
                "advertisers",
                "spend",
                "impressions",
                "clicks",
                "video_views",
                "completed_views",
                "cpm",
                "ctr",
                "cpc",
                "vcr",
              ],
              showTrends: true,
              compactFormat: true,
              trendCalculation: "pop",
            },
            chartType: "summary",
          },
        },
        {
          grid: {
            h: 8,
            i: "3",
            w: 7,
            x: 5,
            y: 10,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Campaign By Industry",
            family: "distribution",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              showItems: 8,
              xDimension: 8,
              yDimension: 32,
              compactFormat: true,
              displayMetric: "impressions",
              legendPosition: null,
              decompositionMetric: "industry",
            },
            chartType: "waffleChart",
          },
        },
        {
          grid: {
            h: 6,
            i: "4",
            w: 12,
            x: 0,
            y: 4,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Spend And Impressions Over Time",
            family: "overtime",
            widget: "ChartWidget",
            settings: {
              xAxis: "month",
              palette: 0,
              xAxisTitle: "Month",
              yAxisPrimary: "spend",
              compactFormat: true,
              lineThickness: 3,
              legendPosition: "bottom",
              yAxisSecondary: "impressions",
              yAxisPrimaryTitle: "Spend",
              yAxisSecondaryTitle: "Impressions",
            },
            chartType: "lineChart",
          },
        },
        {
          grid: {
            h: 8,
            i: "5",
            w: 5,
            x: 0,
            y: 10,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Spend By Business Goal",
            family: "decomposition",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              showItems: 8,
              compactFormat: true,
              displayMetric: "spend",
              legendPosition: null,
              decompositionMetric: "business_goal",
            },
            chartType: "treeMap",
          },
        },
        {
          grid: {
            h: 5,
            i: "6",
            w: 4,
            x: 0,
            y: 18,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Most Executed Channel",
            family: "decomposition",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              holeSize: 50,
              showItems: 5,
              compactFormat: true,
              displayMetric: "spend",
              legendPosition: null,
              decompositionMetric: "channel",
            },
            chartType: "doughnutChart",
          },
        },
        {
          grid: {
            h: 5,
            i: "7",
            w: 4,
            x: 4,
            y: 18,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Most Executed Campaign KPI",
            family: "decomposition",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              holeSize: 50,
              showItems: 5,
              compactFormat: true,
              displayMetric: "spend",
              legendPosition: null,
              decompositionMetric: "campaign_kpi",
            },
            chartType: "doughnutChart",
          },
        },
        {
          grid: {
            h: 5,
            i: "8",
            w: 4,
            x: 8,
            y: 18,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Media Types",
            family: "decomposition",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              holeSize: 50,
              showItems: 5,
              compactFormat: true,
              displayMetric: "spend",
              legendPosition: null,
              decompositionMetric: "media_type",
            },
            chartType: "doughnutChart",
          },
        },
      ],
      idTemplate: "global-overview",
      filterState: {
        filterParams: {
          agency: ["All"],
          advertiser: ["All"],
          businessGoal: ["All"],
          campaign: ["All"],
          campaignKpi: ["All"],
          campaignId: ["All"],
          campaignBudget: ["All"],
          channel: [
            "CTV",
            "Display",
            "Google Search",
            "LinkedIn",
            "Meta",
            "Native",
            "OLV",
            "OOH",
            "Radio",
            "Television",
            "YouTube",
          ],
          dateRange: { type: "preset", value: { preset: "last12Months" } },
          industry: ["All"],
        },
        selectedFilters: ["dateRange", "industry", "businessGoal", "campaignKpi", "channel"],
      },
    },
    {
      icon: "kpi",
      title: "Business Goals",
      idTemplate: "business-goals",
      layout: [
        {
          grid: {
            h: 4,
            i: "1",
            w: 12,
            x: 0,
            y: 0,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Summary",
            family: "other",
            widget: "SummaryWidget",
            settings: {
              metrics: [
                "advertisers",
                "spend",
                "impressions",
                "clicks",
                "video_views",
                "completed_views",
                "cpm",
                "ctr",
                "cpc",
                "vcr",
              ],
              showTrends: true,
              compactFormat: true,
              trendCalculation: "pop",
            },
            chartType: "summary",
          },
        },
        {
          grid: {
            h: 9,
            i: "2",
            w: 12,
            x: 0,
            y: 4,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Channel Analysis",
            family: "comparison",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              showItems: 8,
              xAxisName: "Spend",
              yAxisName: "Business Goal",
              compactFormat: true,
              displayMetric: "spend",
              legendPosition: "bottom",
              yAxisSecondaryName: "Channel",
              decompositionMetric: "business_goal",
              decompositionMetricSecondary: "channel",
            },
            chartType: "stackedBar100Chart",
          },
        },
        {
          grid: {
            h: 9,
            i: "3",
            w: 12,
            x: 0,
            y: 13,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Business Goal",
            family: "other",
            widget: "TableWidget",
            settings: {
              columns: [
                {
                  title: "Campaign",
                  value: "campaign",
                },
                {
                  title: "Business Goal",
                  value: "business_goal",
                },
                {
                  title: "Channel",
                  value: "channel",
                },
                {
                  title: "Spend",
                  value: "spend",
                },
                {
                  title: "Impressions",
                  value: "impressions",
                },
              ],
              striped: false,
              rowsPerPage: 25,
              compactFormat: false,
              summaryRow: true,
            },
            chartType: "dataTable",
          },
        },
      ],
      filterState: {
        filterParams: {
          agency: ["All"],
          advertiser: ["All"],
          businessGoal: ["All"],
          campaign: ["All"],
          campaignKpi: ["All"],
          campaignId: ["All"],
          campaignBudget: ["All"],
          channel: [
            "CTV",
            "Display",
            "Google Search",
            "LinkedIn",
            "Meta",
            "Native",
            "OLV",
            "OOH",
            "Radio",
            "Television",
            "YouTube",
          ],
          dateRange: { type: "preset", value: { preset: "last12Months" } },
          industry: ["All"],
        },
        selectedFilters: ["dateRange", "industry", "businessGoal", "campaignKpi", "channel"],
      },
    },
    {
      icon: "benchmarking",
      title: "Benchmarking",
      idTemplate: "benchmarking",
      layout: [
        {
          grid: {
            h: 8,
            i: "1",
            w: 8,
            x: 4,
            y: 4,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Ad Efficiency vs. Ad Effectiveness",
            family: "relationships",
            widget: "ChartWidget",
            settings: {
              xAxis: "ctr",
              yAxis: "cpm",
              palette: 0,
              showItems: null,
              displayMetric: "spend",
              xAxisTitle: "CTR",
              yAxisTitle: "CPM",
              decompositionMetric: "channel",
              compactFormat: true,
              legendPosition: null,
              dimensionSelector: true,
            },
            chartType: "bubbleChart",
          },
        },
        {
          grid: {
            h: 7,
            i: "2",
            w: 12,
            x: 0,
            y: 20,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Spend And Purchase By Channel ",
            family: "comparison",
            widget: "ChartWidget",
            settings: {
              xAxis: "channel",
              palette: 0,
              showItems: 5,
              xAxisTitle: "Channel",
              yAxisPrimary: "spend",
              compactFormat: true,
              legendPosition: "bottom",
              yAxisSecondary: "purchase_value",
              yAxisPrimaryTitle: "Spend",
              yAxisSecondaryTitle: "Purchase Value",
              dimensionSelector: true,
            },
            chartType: "groupedColumnChart",
          },
        },
        {
          grid: {
            h: 8,
            i: "3",
            w: 12,
            x: 0,
            y: 12,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Benchmarking",
            family: "distribution",
            widget: "ChartWidget",
            settings: {
              palette: 0,
              showItems: 5,
              xAxisTitle: "CTR",
              yAxisTitle: "Channel",
              compactFormat: true,
              displayMetric: "ctr",
              legendPosition: null,
              decompositionMetric: "channel",
              dimensionSelector: true,
            },
            chartType: "dumbbellPlotHorizontal",
          },
        },
        {
          grid: {
            h: 4,
            i: "4",
            w: 12,
            x: 0,
            y: 0,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Summary",
            family: "recommended",
            widget: "SummaryWidget",
            settings: {
              metrics: [
                "advertisers",
                "spend",
                "impressions",
                "clicks",
                "video_views",
                "completed_views",
                "cpm",
                "ctr",
                "cpc",
                "vcr",
              ],
              showTrends: true,
              compactFormat: true,
              trendCalculation: "pop",
            },
            chartType: "summary",
          },
        },
        {
          grid: {
            h: 8,
            i: "5",
            w: 4,
            x: 0,
            y: 4,
            minH: 1,
            moved: false,
          },
          content: {
            title: "Channel Benchmarks",
            family: "other",
            widget: "TableWidget",
            controls: {},
            settings: {
              columns: [
                {
                  title: "Channel",
                  value: "channel",
                },
                {
                  title: "CTR",
                  value: "ctr",
                },
              ],
              striped: false,
              rowsPerPage: 25,
              compactFormat: false,
            },
            chartType: "dataTable",
          },
        },
      ],
      filterState: {
        filterParams: {
          agency: ["All"],
          advertiser: ["All"],
          businessGoal: ["All"],
          campaign: ["All"],
          campaignKpi: ["All"],
          campaignId: ["All"],
          campaignBudget: ["All"],
          channel: [
            "CTV",
            "Display",
            "Google Search",
            "LinkedIn",
            "Meta",
            "Native",
            "OLV",
            "OOH",
            "Radio",
            "Television",
            "YouTube",
          ],
          dateRange: { type: "preset", value: { preset: "last12Months" } },
          industry: ["All"],
        },
        selectedFilters: ["dateRange", "industry", "businessGoal", "campaignKpi", "channel"],
      },
    },
  ],
};
