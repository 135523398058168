import { ContentWidget } from "@/store/modules/userSettings";

export interface PageEdit {
  activeWidget: ContentWidget;
  showDrawerConfig: boolean;
}

export default {
  namespaced: true,
  state: {
    activeWidget: {} as ContentWidget,
    showDrawerConfig: false,
  },

  mutations: {
    updateSelectWidget(state: PageEdit, item: ContentWidget): void {
      state.activeWidget = item;
    },

    updateShowDrawerConfig(state: PageEdit, value: boolean): void {
      state.showDrawerConfig = value;
    },
  },

  actions: {},

  getters: {
    isShowDrawerConfig: (state: PageEdit): boolean => {
      return state.showDrawerConfig;
    },

    activeWidget: (state: PageEdit): ContentWidget => {
      return state.activeWidget;
    },
  },
};
