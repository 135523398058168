import { VALIDATORS } from "@/helpers/validators";
import { compactFormat, palette } from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OTHER],
  title: "Bullet Chart",
  defaultHeight: 6,
  settings: {
    compactFormat: { ...compactFormat, gridPosition: [3, 1] },
    yAxis: {
      default: "spend",
      type: "select",
      label: "Vertical Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    qualitativeRange: {
      default: null,
      type: "number",
      label: "Qualitative Range 1",
      dictionary: "qualitativeRange",
      gridPosition: [1, 0],
      rules: [VALIDATORS.REQUIRED],
    },
    qualitativeRange2: {
      default: null,
      type: "number",
      label: "Qualitative Range 2",
      dictionary: "qualitativeRange",
      gridPosition: [2, 0],
      rules: [VALIDATORS.REQUIRED],
      dependencies: [
        {
          names: ["qualitativeRange"],
          type: "validation",
          rule: (dependValues: string[], val: string): boolean => {
            const [dependVal] = dependValues;
            return Number(val) > Number(dependVal);
          },
        },
      ],
    },
    qualitativeRange3: {
      default: null,
      type: "number",
      label: "Qualitative Range 3",
      dictionary: "qualitativeRange",
      gridPosition: [3, 0],
      rules: [VALIDATORS.REQUIRED],
      dependencies: [
        {
          names: ["qualitativeRange2"],
          type: "validation",
          rule: (dependValues: string[], val: string): boolean => {
            const [dependVal] = dependValues;
            return Number(val) > Number(dependVal);
          },
        },
      ],
    },
    qualitativeRange4: {
      default: null,
      type: "number",
      label: "Qualitative Range 4 (Optional)",
      dictionary: "qualitativeRangeOptional",
      gridPosition: [4, 0],
      dependencies: [
        {
          names: ["qualitativeRange3"],
          type: "validation",
          rule: (dependValues: string[], val: string): boolean => {
            const [dependVal] = dependValues;
            return Number(val) > Number(dependVal) || !val;
          },
        },
      ],
    },
    qualitativeRange5: {
      default: null,
      type: "number",
      label: "Qualitative Range 5 (Optional)",
      dictionary: "qualitativeRangeOptional",
      gridPosition: [5, 0],
      dependencies: [
        {
          names: ["qualitativeRange4"],
          type: "validation",
          rule: (dependValues: string[], val: string): boolean => {
            const [dependVal] = dependValues;
            return (!!dependVal && Number(val) > Number(dependVal)) || !val;
          },
        },
        {
          names: ["qualitativeRange4"],
          type: "disable",
          rule: (dependValues: string[]): boolean => {
            const [dependVal] = dependValues;
            return !dependVal;
          },
          newValues: [
            {
              propName: "qualitativeRange5",
              condition: (ruleResult: boolean): boolean => {
                return ruleResult;
              },
              value: null,
            },
          ],
        },
      ],
    },
    targetValue: {
      default: undefined,
      type: "targetValue",
      labelCheckBox: "Show Target",
      label: "Target Value",
      gridPosition: [0, 1],
      tooltipOrder: 1,
      rules: [VALIDATORS.REQUIRED],
      dependencies: [
        {
          names: [
            "qualitativeRange",
            "qualitativeRange2",
            "qualitativeRange3",
            "qualitativeRange4",
            "qualitativeRange5",
          ],
          type: "validation",
          rule: (dependValues: string[], val: string): boolean => {
            const prepareValues: number[] = dependValues.map((el) => {
              return Number(el);
            });
            const dependVal = Math.max(...prepareValues);
            return Number(val) <= Number(dependVal);
          },
        },
      ],
    },
    minValueColor: {
      default: "#C19270",
      type: "color",
      label: "Min Value Color",
      dictionary: "colors",
      gridPosition: [1, 1],
    },
    maxValueColor: {
      default: "#FFD4B7",
      type: "color",
      label: "Max Value Color",
      dictionary: "colors",
      gridPosition: [2, 1],
    },
    palette,
  },
};
