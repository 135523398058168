export default {
  accountManagementTable: {
    agency: "Agency",
    name: "Company Name",
    email: "Contact Email",
    country: "Country",
    remarks: "Remarks",
    actions: "Actions",
  },
  userManagementTable: {
    name: "Display Name",
    email: "Email Address",
    account: "Account",
    role: "Role",
    actions: "Actions",
  },
  templateLibrary: {
    templateName: "Template Name",
    createdBy: "Created By",
    dateUpdated: "Last Date Updated",
    actions: "Actions",
  },
  glossary: {
    calculatedConversion: "Calculated Conversion",
    definition: "Definition",
    associatedCampaignKpi: "Associated Campaign KPI",
  },
};
