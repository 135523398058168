import lineSeriesChartConfig from "@/settings/charts/lineSeriesChart";
import { granularity } from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME],
  name: "stepLineSeriesChart",
  title: "Step Line Series Chart",
  defaultHeight: 6,
  controls: [granularity],
  settings: lineSeriesChartConfig.settings,
};
