import { cloneDeep } from "lodash";
import { ActionContext } from "vuex";

import Api from "@/api/Api";
import { DashboardConfig, WidgetConfig } from "@/classes/UserSettingsManagement";
import Lang from "@/lang";
import { API_ROUTES, requestTokens } from "@/settings/apiRoutes";
import store, { RootState } from "@/store";
import { CatalogsState } from "@/store/modules/catalogs";
import { FilterParams, FilterState, ItemLayout } from "@/store/modules/userSettings";

export interface TemplateConfig extends DashboardConfig {
  idTemplate: string;
  icon: string;
  id?: string;
  templateImage?: string;
  data?: TemplateData;
}

export interface TemplateData {
  settingsVersion: string;
  filterState: FilterState;
  layout: ItemLayout[];
}

export interface TemplateDataApi {
  layout: Array<WidgetConfig>;
  filterState: FilterState;
  settingsVersion: string;
}

export interface TemplateConfigApi {
  id: string;
  title: string;
  data: TemplateDataApi;
  updated_at: string | null;
  created_at: string | null;
  updated_by: string | null;
  created_by: string | null;
}

export interface TemplateSettings {
  templates: TemplateConfig[];
}

interface DashboardTemplates {
  dashboardTemplates: TemplateSettings;
  selectedTemplate: null | TemplateConfig;
  isLoading: boolean;
}

const templatesState: DashboardTemplates = {
  dashboardTemplates: { templates: [] },
  selectedTemplate: null,
  isLoading: false,
};

export default {
  namespaced: true,

  state: templatesState,

  actions: {
    async fetchTemplates(ctx: ActionContext<DashboardTemplates, RootState>): Promise<void> {
      ctx.commit("updateLoading", true);
      const res = await Api.request<{ data: unknown } | undefined>({
        path: `${API_ROUTES.TEMPLATES.PATH}`,
        token: requestTokens.apiGetTemplates,
        defaultResult: undefined,
        errorText: Lang.errorGetTemplates,
      });

      if (res !== undefined) {
        ctx.commit("updateTemplates", res.data);
      }

      ctx.commit("updateLoading", false);
    },

    async deleteTemplate(ctx: ActionContext<DashboardTemplates, RootState>, template: TemplateConfig): Promise<void> {
      ctx.commit("updateLoading", true);
      await Api.request({
        path: `${API_ROUTES.TEMPLATES.PATH}/${template.id}`,
        method: "del",
        defaultResult: undefined,
        errorText: Lang.errorDeleteTemplate,
        token: requestTokens.apiDelTemplate,
        successCallback: async () => {
          await ctx.dispatch("fetchTemplates");
          store.commit("app/showAlert", {
            type: "success",
            message: Lang.successTemplateDeleted(template.title),
          });
        },
        errorCallback: () => {
          ctx.commit("updateLoading", false);
        },
      });

      ctx.commit("updateLoading", false);
    },
  },

  mutations: {
    updateLoading(state: CatalogsState, val: boolean): void {
      state.isLoading = val;
    },

    updateSelectedTemplate(state: DashboardTemplates, item: TemplateConfig): void {
      state.selectedTemplate = item;
    },

    updateFilterState(state: DashboardTemplates, filterState: FilterParams): void {
      if (!state.selectedTemplate) {
        return;
      }
      const preparedTemplate = cloneDeep(state.selectedTemplate);
      preparedTemplate.filterState.filterParams = filterState;

      state.selectedTemplate = preparedTemplate;
    },

    resetSelectedTemplate(state: DashboardTemplates): void {
      state.selectedTemplate = null;
    },

    updateTemplates(state: DashboardTemplates, items: TemplateConfig[]): void {
      state.dashboardTemplates.templates = items;
    },
  },

  getters: {
    listDashboardTemplates: (state: DashboardTemplates): TemplateConfig[] => {
      return state.dashboardTemplates.templates;
    },

    selectedTemplate: (state: DashboardTemplates): TemplateConfig | null => {
      return state.selectedTemplate;
    },

    isLoading(state: CatalogsState): boolean {
      return state.isLoading;
    },
  },
};
