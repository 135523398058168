import { API } from "aws-amplify";

import Lang from "@/lang";
import {
  AWS_SERVICE_NAME,
  RequestMethods,
  apiErrorCodes,
  apiErrorStatuses,
  requestStatusesDictionary,
} from "@/settings/apiRoutes";
import store from "@/store";
import { NoDataStatusItem } from "@/store/modules/charts";

interface Config<T> {
  path: string;
  defaultResult: T;
  method?: RequestMethods;
  noDataItem?: NoDataStatusItem;
  errorText?: string;
  errorCallback?: () => void;
  successCallback?: () => void;
  token: string;

  // Wide variants of requests payloads.
  /* eslint-disable @typescript-eslint/no-explicit-any */
  body?: { [key: string]: any };
  params?: { [key: string]: any };
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export const REQUEST_CANCEL_MARK = "request_canceled";

export default class Api {
  static async request<T>(config: Config<T>): Promise<T> {
    const method = (config.method || "get").toLowerCase();
    const params = method === "get" ? { queryStringParameters: config.params || {} } : { body: config.body || {} };

    const timestamp = Date.now();
    store.commit("charts/updateRequestStatus", {
      timestamp,
      method: config.method,
      path: config.path,
      token: config.token,
      status: requestStatusesDictionary.sent,
    });

    try {
      // We need to add string signature for index for API class, that's why we use 'any' here.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = (API as Record<string, any>)[method as string](AWS_SERVICE_NAME, config.path, params);

      store.commit("app/pushRequest", { token: config.token, promise: res });

      const data = await res;

      if (typeof config.successCallback === "function") {
        config.successCallback();
      }

      store.commit("charts/updateRequestStatus", {
        timestamp,
        status: requestStatusesDictionary.answered,
      });

      return data;
    } catch (e) {
      store.commit("charts/updateRequestStatus", {
        timestamp,
        status: requestStatusesDictionary.error,
      });

      if ((e as Record<string, string>).message === REQUEST_CANCEL_MARK) {
        return config.defaultResult;
      }

      if (config.noDataItem) {
        store.commit("charts/updateNoDataStatus", config.noDataItem);
      }

      if (typeof config.errorCallback === "function") {
        config.errorCallback();
      }

      const statusError = apiErrorStatuses.find((el) => el.status === (e as XMLHttpRequest).response?.status);
      const codeError = apiErrorCodes.find((el) => el.code === (e as XMLHttpRequest).response?.data?.error_code);

      store.commit("app/showAlert", {
        message: statusError?.message || codeError?.message || config.errorText || Lang.unknownError,
        type: "error",
      });
    }

    return config.defaultResult;
  }
}
