export const imageDictionary: Record<string, Record<string, string>> = {
  default: {
    image: "default-chart.png",
    icon: "vila-default",
  },
  areaChart: {
    image: "area-chart.png",
    icon: "vila-area",
  },
  barChart: {
    image: "bar-chart.png",
    icon: "vila-bar",
  },
  bubbleChart: {
    image: "bubble-chart.png",
    icon: "vila-bubble",
  },
  boxPlot: {
    image: "box-plot-chart.png",
    icon: "vila-box-plot",
  },
  boxPlotHorizontal: {
    image: "box-plot-horizontal-chart.png",
    icon: "vila-box-plot-h",
  },
  bulletChart: {
    image: "bullet-chart.png",
    icon: "vila-bullet",
  },
  columnChart: {
    image: "column-chart.png",
    icon: "vila-column",
  },
  comboBarLineChart: {
    image: "combo-bar-line-chart.png",
    icon: "vila-combo-bar-line",
  },
  comboColumnLineChart: {
    image: "combo-column-line-chart.png",
    icon: "vila-combo-column-line",
  },
  dataTable: {
    image: "data-table.png",
    icon: "vila-table",
  },
  doughnutChart: {
    image: "doughnut-chart.png",
    icon: "vila-doughnut",
  },
  dumbbellPlot: {
    image: "dumbbell-plot-chart.png",
    icon: "vila-dumbbell-plot",
  },
  dumbbellPlotHorizontal: {
    image: "dumbbell-plot-horizontal-chart.png",
    icon: "vila-dumbbell-plot-h",
  },
  funnelChart: {
    image: "funnel-chart.png",
    icon: "vila-funnel",
  },
  groupedBarChart: {
    image: "grouped-bar-chart.png",
    icon: "vila-bar-grouped",
  },
  groupedColumnChart: {
    image: "grouped-column-chart.png",
    icon: "vila-column-grouped",
  },
  heatMap: {
    image: "heat-map-chart.png",
    icon: "vila-heat-map",
  },
  lineChart: {
    image: "line-chart.png",
    icon: "vila-line",
  },
  lineSeriesChart: {
    image: "line-series-chart.png",
    icon: "vila-line-series",
  },
  lollipopChart: {
    image: "lollipop-chart.png",
    icon: "vila-lollipop",
  },
  nestedDoughnutChart: {
    image: "nested-doughnut-chart.png",
    icon: "vila-doughnut-nested",
  },
  packedCircleChart: {
    image: "packed-circle-chart.png",
    icon: "vila-packed-circle",
  },
  pieChart: {
    image: "pie-chart.png",
    icon: "vila-pie",
  },
  scatterPlot: {
    image: "scatter-plot-chart.png",
    icon: "vila-scatter-plot",
  },
  stackedAreaChart: {
    image: "stacked-area-chart.png",
    icon: "vila-area-stacked",
  },
  stackedArea100Chart: {
    image: "stacked-area-100-chart.png",
    icon: "vila-area-stacked-100",
  },
  stackedBar100Chart: {
    image: "stacked-bar-100-chart.png",
    icon: "vila-bar-stacked-wide-100",
  },
  stackedBarChart: {
    image: "stacked-bar-chart.png",
    icon: "vila-bar-stacked-wide",
  },
  stackedColumn100Chart: {
    image: "stacked-column-100-chart.png",
    icon: "vila-column-stacked-wide-100",
  },
  stackedColumnChart: {
    image: "stacked-column-chart.png",
    icon: "vila-column-stacked-wide",
  },
  stepLineChart: {
    image: "step-line-chart.png",
    icon: "vila-line-step",
  },
  stepLineSeriesChart: {
    image: "step-line-series-chart.png",
    icon: "vila-line-step-series",
  },
  streamChart: {
    image: "stream-chart.png",
    icon: "vila-stream",
  },
  summary: {
    image: "summary.png",
    icon: "vila-summary",
  },
  treeMap: {
    image: "tree-map-chart.png",
    icon: "vila-tree-map",
  },
  waffleChart: {
    image: "waffle-chart.png",
    icon: "vila-waffle",
  },
  yearOverYearLineChart: {
    image: "year-over-year-line-chart.png",
    icon: "vila-line-yoy",
  },
  yearOverYearStepLineChart: {
    image: "year-over-year-step-line-chart.png",
    icon: "vila-line-step-yoy",
  },
};
