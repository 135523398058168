import {
  compactFormat,
  dimension,
  dimensionSelector,
  granularity,
  legendPosition,
  palette,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME, FAMILY.COMPARISON],
  recommendedFamily: [FAMILY.COMPARISON],
  title: "Grouped Bar Chart",
  defaultHeight: 6,
  controls: [{ ...dimension, metricName: "yAxis" }, granularity],
  settings: {
    palette,
    showItems: { ...showItems, gridPosition: [3, 0] },
    legendPosition: { ...legendPosition, gridPosition: [4, 0] },
    compactFormat: { ...compactFormat, gridPosition: [5, 0] },

    yAxis: {
      default: "channel",
      type: "select",
      label: "Vertical Axis Metric",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },

    xAxisPrimary: {
      default: "spend",
      type: "select",
      label: "Primary Horizontal Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },

    xAxisSecondary: {
      default: "impressions",
      type: "select",
      label: "Secondary Horizontal Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
      tooltipOrder: 2,
    },

    yAxisTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis Title",
      gridPosition: [0, 1],
      forAxis: "yAxis",
    },

    xAxisTitlePrimary: {
      default: "",
      type: "text",
      label: "Primary Horizontal Axis Title",
      gridPosition: [1, 1],
      forAxis: "xAxisPrimary",
    },

    xAxisTitleSecondary: {
      default: "",
      type: "text",
      label: "Secondary Horizontal Axis Title",
      gridPosition: [2, 1],
      forAxis: "xAxisSecondary",
    },
  },
  familySettings: {
    [FAMILY.OVERTIME]: {
      legendPosition: { ...legendPosition, gridPosition: [3, 0] },
      compactFormat: { ...compactFormat, gridPosition: [4, 0] },
      showItems: undefined,

      yAxis: {
        default: "month",
        type: "select",
        label: "Vertical Axis Metric",
        dictionary: "timeMetrics",
        apiParameter: "dimension",
        gridPosition: [0, 0],
        tooltipOrder: 0,
      },
    },
    [FAMILY.COMPARISON]: {
      dimensionSelector: { ...dimensionSelector, gridPosition: [6, 0] },
    },
  },
};
