import { NavigationGuardNext, Route } from "vue-router";

import { AclType } from "@/acl/aclSettings";
import { routes } from "@/router";
import ROUTES from "@/settings/routes";

type BeforeEachHookParamsType = {
  to: Route;
  from: Route;
  next: NavigationGuardNext;
};

const CurrentAcl = class CurrentAcl {
  pathTo!: string;
  pathFrom!: string;
  next!: NavigationGuardNext;
  userAcl!: Record<string, Record<string, AclType>>;
  routeAclGroup!: string;
  noAccessPath: string;
  allowedRoutes: string[];

  constructor() {
    this.noAccessPath = "/ROUTES.NO_ACCESS";
    this.allowedRoutes = [];
  }

  private init(routeHookParams: BeforeEachHookParamsType, userAcl: Record<string, Record<string, AclType>>): void {
    this.pathTo = routeHookParams.to.path;
    this.pathFrom = routeHookParams.from.path;
    this.next = routeHookParams.next;
    this.userAcl = userAcl;
    this.routeAclGroup = routeHookParams.to.name || "";
    this.allowedRoutes = this.getAllowedRoutes(userAcl);
  }

  private getAllowedRoutes(userAcl: Record<string, Record<string, AclType>>) {
    const allowedRoute = [];
    for (const page in userAcl.pages) {
      allowedRoute.push(ROUTES.NOT_FOUND.PATH);
      allowedRoute.push(page);

      userAcl.pages[page]?.permissions?.forEach((permission: string): void => {
        allowedRoute.push(`${page}/${permission}`);
      });
    }
    return allowedRoute;
  }

  processRoute(routeHookParams: BeforeEachHookParamsType, userAcl = {}): void {
    this.init(routeHookParams, userAcl);

    // If the current route available for the user, open the route.
    if (this.isRoutePermitted(this.pathTo)) {
      return this.next();
    }

    // If there is no such route, we get the default route, for the first user group. And go to this route.
    if (this.getDefaultRoute()) {
      return this.next(this.getDefaultRoute());
    }
  }

  private getDefaultRoute(): false | string {
    const defaultRoute = routes.find((route) => {
      if (!this.userAcl?.pages) {
        return false;
      }
      return Object.keys(this.userAcl.pages).some((routeAclGroup) => {
        return route.path === routeAclGroup && this.userAcl.pages[routeAclGroup].isDefault;
      });
    });
    return defaultRoute ? defaultRoute.path : false;
  }

  isRoutePermitted(path: string): boolean {
    const preparedPath = `/${path.split("/")[1]}`;

    return !!this.allowedRoutes.find((page: string) => {
      return page === preparedPath;
    });
  }
};

export default CurrentAcl;
