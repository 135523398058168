import {
  compactFormat,
  dimension,
  dimensionSelector,
  granularity,
  legendPosition,
  palette,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME, FAMILY.COMPARISON, FAMILY.DISTRIBUTION],
  recommendedFamily: [FAMILY.COMPARISON],
  title: "Column Chart",
  defaultHeight: 6,
  controls: [{ ...dimension, metricName: "xAxis" }, granularity],
  settings: {
    showItems: { ...showItems, gridPosition: [2, 0] },
    legendPosition: { ...legendPosition, gridPosition: [3, 0] },
    compactFormat: { ...compactFormat, gridPosition: [4, 0] },
    xAxis: {
      default: "channel",
      type: "select",
      label: "Horizontal Axis Metric",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    yAxisPrimary: {
      default: "spend",
      type: "select",
      label: "Vertical Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },
    xAxisTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [0, 1],
      forAxis: "xAxis",
    },
    yAxisPrimaryTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis Title",
      gridPosition: [1, 1],
      forAxis: "yAxisPrimary",
    },
    palette,
  },
  familySettings: {
    [FAMILY.OVERTIME]: {
      legendPosition: { ...legendPosition, gridPosition: [2, 0] },
      compactFormat: { ...compactFormat, gridPosition: [3, 0] },
      showItems: undefined,

      xAxis: {
        default: "month",
        type: "select",
        label: "Horizontal Axis Metric",
        dictionary: "timeMetrics",
        apiParameter: "dimension",
        gridPosition: [0, 0],
        tooltipOrder: 0,
      },
    },
    [FAMILY.COMPARISON]: {
      dimensionSelector: { ...dimensionSelector, gridPosition: [5, 0] },
    },
    [FAMILY.DISTRIBUTION]: {
      dimensionSelector: { ...dimensionSelector, gridPosition: [5, 0] },
    },
  },
};
