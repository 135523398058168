import { ILabelSettings } from "@amcharts/amcharts5";
import { forIn, merge } from "lodash";
import { v4 as uuid } from "uuid";

import { VISUAL_DATE_FORMAT, VISUAL_MONTH_FORMAT, VISUAL_QUARTER_FORMAT } from "@/helpers/formatters";
import { chartsSettings, getChartAllControls } from "@/settings/charts";
import inputsDictionary from "@/settings/inputsDictionary";
import { WIDGET_TAB_SPECIAL, widgetTabs } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export interface AvailableWidget {
  id: string;
  widget: string;
  title: string;
  chartType: string;
  chartName: string;
  defaultHeight: number;
  draft?: string[];
  settings: Record<string, unknown>;
  controls: Record<string, unknown>[];
  family: string;
}

export interface AvailableTab {
  id?: string;
  title: string;
  family: string;
  list: AvailableWidget[];
}

export interface FamilyTab {
  title: string;
  family: string;
  order: number;
  list: AvailableWidget[];
}

export const WIDGETS_TYPES = {
  SUMMARY: "SummaryWidget",
  CHART: "ChartWidget",
  TABLE: "TableWidget",
};

const getWidgetType = (chartName: string) => {
  switch (chartName) {
    case "summary":
      return WIDGETS_TYPES.SUMMARY;
    case "dataTable":
      return WIDGETS_TYPES.TABLE;
    default:
      return WIDGETS_TYPES.CHART;
  }
};

export const getAxisTitle = (
  settings: Record<string, Record<string, string | number | boolean>>,
  axisParam: string,
  value?: string
): string => {
  const dictionaryName = settings[axisParam].dictionary;

  if (typeof dictionaryName !== "string") {
    return "";
  }

  return (
    inputsDictionary[dictionaryName].find((el) => {
      return el.value === (value ? value : settings[axisParam].default);
    })?.title || ""
  );
};

const getPreparedSettings = (settings: Record<string, Record<string, string | number | boolean>>) => {
  // TODO: Simplify if it's possible.
  const preparedSettings: Record<string, unknown> = {};

  forIn(settings, (setting, key) => {
    const defaultValue = setting?.forAxis ? getAxisTitle(settings, setting.forAxis as string) : setting?.default;

    preparedSettings[key] = setting?.value === undefined ? defaultValue : setting?.value;
  });

  return preparedSettings;
};

export const getChartSettings = (chartName: string, familyName: string) => {
  const settingsByFamily = chartsSettings[chartName]?.familySettings?.[familyName];

  if (settingsByFamily) {
    const settings = merge({}, chartsSettings[chartName].settings, settingsByFamily);
    return getPreparedSettings(settings);
  }

  return getPreparedSettings(chartsSettings[chartName].settings);
};

const getChartSettingGroup = (
  chartName: string,
  groupName?: string,
  familyName?: string
): string | number | string[] | null => {
  if (!familyName || chartsSettings[chartName][groupName as keyof Config][familyName] === undefined) {
    return chartsSettings[chartName][groupName as keyof Config];
  }

  return chartsSettings[chartName][groupName as keyof Config][familyName];
};

const getControlValues = (controls: Record<string, string | number | boolean | object>[]) => {
  return controls.map((control) => ({
    [control.name as string]: control.value !== undefined ? control.value : control.default,
  }));
};

const getChartControlValues = (chartName: string, familyName: string) => {
  const controls = getChartAllControls(chartName, familyName);

  return controls ? getControlValues(controls) : [];
};

export const getChartsWidgets = (charts: string[], familyName: string): AvailableWidget[] => {
  const availableCharts = [] as AvailableWidget[];

  charts.forEach((chartName) => {
    availableCharts.push({
      id: uuid(),
      widget: getWidgetType(chartName),
      chartType: chartName,
      chartName: getChartSettingGroup(chartName, "title") as string,
      title: getChartSettingGroup(chartName, "title") as string,
      defaultHeight: (getChartSettingGroup(chartName, "defaultHeight") as number) || 5,
      draft: getChartSettingGroup(chartName, "draft") as string[],
      family: familyName,
      settings: getChartSettings(chartName, familyName),
      controls: getChartControlValues(chartName, familyName),
    });
  });

  return availableCharts.sort((a, b) => (a.title > b.title ? 1 : -1));
};

const getAllCharts = (isRecommended: boolean): AvailableWidget[] => {
  const chartsByTab = [] as AvailableWidget[];
  const allSettings = Object.entries(chartsSettings);

  allSettings.forEach((el) => {
    const [chartName, setting] = el;
    const charts = isRecommended ? setting.recommendedFamily : setting.family;

    if (charts?.length) {
      forIn(charts, (family) => {
        chartsByTab.push(...getChartsWidgets([chartName], family as string));
      });
    }
  });

  return chartsByTab
    .sort((a, b) => (widgetTabs[a.family].title > widgetTabs[b.family].title ? 1 : -1))
    .sort((a, b) => (b.title <= a.title ? 1 : -1));
};

const getChartsByFamily = (familyName: string): string[] => {
  const charts = [] as string[];
  const allSettings = Object.entries(chartsSettings);

  allSettings.forEach((el) => {
    if (el[1].family.includes(familyName)) {
      charts.push(el[0]);
    }
  });

  return charts;
};

const createFamilyTabs = () => {
  const familyTabs = [] as FamilyTab[];

  forIn(widgetTabs, (family) => {
    const { title, name, order } = family;
    const isRecommended = name === WIDGET_TAB_SPECIAL.RECOMMENDED;

    familyTabs.push({
      title,
      family: name,
      order,
      list: [WIDGET_TAB_SPECIAL.RECOMMENDED, WIDGET_TAB_SPECIAL.ALL].includes(name)
        ? getAllCharts(isRecommended)
        : getChartsWidgets(getChartsByFamily(name), name),
    });
  });

  return familyTabs.sort((a, b) => a.order - b.order);
};

export const familyTabs = createFamilyTabs();

export const getTimeAxisName = (currentSettings: Record<string, unknown>): string => {
  return (
    Object.keys(currentSettings).find((key) =>
      inputsDictionary.timeMetrics.find((timeMetric) => timeMetric.value === currentSettings[key])
    ) || ""
  );
};

export const chartFontSettings: Record<string, ILabelSettings> = {
  label: {
    fontFamily: "Roboto",
  },
  legend: {
    fontFamily: "Roboto",
  },
  axisTitle: {
    fontSize: 20,
    fontWeight: "500",
    fontFamily: "Roboto",
  },
};

export const chartInitialSettings = {
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
};

export const dateAxisDateFormats = {
  day: VISUAL_DATE_FORMAT,
  month: VISUAL_MONTH_FORMAT,
  quarter: VISUAL_QUARTER_FORMAT,
};

export const getAxisDateFormat = (timeUnit: string) => {
  switch (timeUnit) {
    case "quarter":
      return { month: VISUAL_QUARTER_FORMAT };
    default:
      return dateAxisDateFormats;
  }
};
