import Lang from "@/lang";

export interface UserRolesConfig {
  label: string;
  value: null | string;
  items: {
    text: string;
    value: string | null;
  }[];
}

export interface UserItem {
  email: string;
  name: string;
  roles: string[];
  titleRoles: string;
  tenant: string | number;
  tenants: number[];
  tenantTitle: string | number;
  is_active?: boolean;
}

export type UserRole = { name: string; title: string };

export const userRoleNames = {
  admins: "admins",
  armUsers: "arm_users",
  users: "users",
};

// The order of the items in this array is important. Higher role goes the first.
export const userRoles: UserRole[] = [
  { name: userRoleNames.admins, title: Lang.admins },
  { name: userRoleNames.armUsers, title: Lang.arm_users },
  { name: userRoleNames.users, title: Lang.users },
];

export const headers: { text: string; value: string; [key: string]: unknown }[] = [
  {
    text: Lang.userManagementTable.name,
    value: "name",
    align: "start",
    class: "header primary--text",
    rowClass: "user-name",
  },
  { text: Lang.userManagementTable.email, value: "email", align: "start", class: "header primary--text" },
  {
    text: Lang.userManagementTable.account,
    value: "tenantTitle",
    align: "start",
    class: "header primary--text",
    rowClass: "tenant",
  },
  {
    text: Lang.userManagementTable.role,
    value: "titleRoles",
    align: "start",
    class: "header primary--text",
    rowClass: "roles",
  },
  {
    text: Lang.userManagementTable.actions,
    value: "actions",
    sortable: false,
    align: "center",
    class: "header primary--text",
  },
];

export const userRolesConfig: UserRolesConfig = {
  label: "Role",
  value: null,
  items: userRoles.map((role) => ({ text: role.title, value: role.name })),
};
