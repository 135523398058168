import { compactFormat, granularity, legendPosition, palette } from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME],
  title: "Area Chart",
  defaultHeight: 6,
  controls: [granularity],
  settings: {
    palette,
    legendPosition: { ...legendPosition, gridPosition: [2, 0] },
    compactFormat: { ...compactFormat, gridPosition: [3, 0] },
    xAxisTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [1, 1],
      forAxis: "xAxis",
    },
    yAxisTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis Title",
      gridPosition: [0, 1],
      forAxis: "yAxis",
    },
    xAxis: {
      default: "month",
      type: "select",
      label: "Decomposition Metric",
      dictionary: "timeMetrics",
      apiParameter: "dimension",
      gridPosition: [1, 0],
      tooltipOrder: 0,
    },
    yAxis: {
      default: "spend",
      type: "select",
      label: "Display Metric",
      dictionary: "displayMetrics",
      gridPosition: [0, 0],
      tooltipOrder: 2,
    },
  },
};
