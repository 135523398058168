import { pickBy } from "lodash";

import areaChart from "@/settings/charts/areaChart";
import barChart from "@/settings/charts/barChart";
import boxPlot from "@/settings/charts/boxPlot";
import boxPlotHorizontal from "@/settings/charts/boxPlotHorizontal";
import bubbleChart from "@/settings/charts/bubbleChart";
import bulletChart from "@/settings/charts/bulletChart";
import columnChart from "@/settings/charts/columnChart";
import comboBarLineChart from "@/settings/charts/comboBarLineChart";
import comboColumnLineChart from "@/settings/charts/comboColumnLineChart";
import doughnutChart from "@/settings/charts/doughnutChart";
import dumbbellPlot from "@/settings/charts/dumbbellPlot";
import dumbbellPlotHorizontal from "@/settings/charts/dumbbellPlotHorizontal";
import funnelChart from "@/settings/charts/funnelChart";
import groupedBarChart from "@/settings/charts/groupedBarChart";
import groupedColumnChart from "@/settings/charts/groupedColumnChart";
import heatMap from "@/settings/charts/heatMap";
import lineChart from "@/settings/charts/lineChart";
import lineSeriesChart from "@/settings/charts/lineSeriesChart";
import lollipopChart from "@/settings/charts/lollipopChart";
import nestedDoughnutChart from "@/settings/charts/nestedDoughnutChart";
import packedCircleChart from "@/settings/charts/packedCircleChart";
import pieChart from "@/settings/charts/pieChart";
import scatterPlot from "@/settings/charts/scatterPlot";
import stackedArea100Chart from "@/settings/charts/stackedArea100Chart";
import stackedAreaChart from "@/settings/charts/stackedAreaChart";
import stackedBar100Chart from "@/settings/charts/stackedBar100Chart";
import stackedBarChart from "@/settings/charts/stackedBarChart";
import stackedColumn100Chart from "@/settings/charts/stackedColumn100Chart";
import stackedColumnChart from "@/settings/charts/stackedColumnChart";
import stepLineChart from "@/settings/charts/stepLineChart";
import stepLineSeriesChart from "@/settings/charts/stepLineSeriesChart";
import streamChart from "@/settings/charts/streamChart";
import treeMap from "@/settings/charts/treeMap";
import waffleChart from "@/settings/charts/waffleChart";
import yearOverYearLineChart from "@/settings/charts/yearOverYearLineChart";
import yearOverYearStepLineChart from "@/settings/charts/yearOverYearStepLineChart";
import summary from "@/settings/summary/summary";
import dataTable from "@/settings/table/table";
import { Config } from "@/types/charts";

export const chartsSettings: Record<string, Config> = {
  doughnutChart,
  bulletChart,
  boxPlot,
  boxPlotHorizontal,
  nestedDoughnutChart,
  pieChart,
  waffleChart,
  lineChart,
  lineSeriesChart,
  stepLineChart,
  stepLineSeriesChart,
  lollipopChart,
  stackedBar100Chart,
  stackedBarChart,
  dumbbellPlotHorizontal,
  groupedColumnChart,
  groupedBarChart,
  dumbbellPlot,
  treeMap,
  heatMap,
  stackedColumn100Chart,
  stackedAreaChart,
  stackedColumnChart,
  bubbleChart,
  columnChart,
  barChart,
  dataTable,
  summary,
  areaChart,
  stackedArea100Chart,
  streamChart,
  scatterPlot,
  packedCircleChart,
  funnelChart,
  comboColumnLineChart,
  comboBarLineChart,
  yearOverYearLineChart,
  yearOverYearStepLineChart,
};

export const axisTitle = {
  marginTop: 10,
};

export const getChartAllControls = (
  chartName: string,
  familyName: string
): Record<string, string | number | boolean | object>[] => {
  const controls = chartsSettings[chartName]?.controls || [];
  return controls.filter((control) => ((control.families as string[]) || []).includes(familyName));
};

export const getChartAllSettings = (chartName: string, familyName: string) => {
  const settings = chartsSettings[chartName]?.settings;
  const settingsByFamily = chartsSettings[chartName]?.familySettings?.[familyName];

  if (settingsByFamily) {
    return pickBy(
      // Do not replace the source on merge, it is important.
      Object.assign({}, chartsSettings[chartName].settings, settingsByFamily),
      (setting: unknown) => setting !== undefined
    );
  }
  return settings;
};

export const displaySettingsNames = [
  "displayMetric",
  "displayMetricSecondary",
  "decompositionMetric",
  "decompositionMetricTop",
  "decompositionMetricSecondary",
  "xAxis",
  "yAxis",
  "xAxisPrimary",
  "yAxisPrimary",
  "xAxisSecondary",
  "yAxisSecondary",
  "stage1Metric",
  "stage2Metric",
  "stage3Metric",
  "stage4Metric",
  "stage5Metric",
];
