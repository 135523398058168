import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { ActionContext } from "vuex";

import router from "@/router";
import { AccountItem } from "@/settings/accountManagement";
import { LOCALSTORAGE_TENANT } from "@/settings/constants";
import ROUTES from "@/settings/routes";
import { userRoles } from "@/settings/userManagement";
import store, { RootState } from "@/store";

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  updated_at: string;
  "custom:bytesQuota": string;
  "custom:bytesUsed": string;
}

interface CognitoUserExt extends CognitoUser {
  attributes: UserAttributes;
}

export interface AuthState {
  isAuthorized: boolean;
  user: CognitoUserExt | null;
  userRoles: string[] | null;
  restoringUsername: string;
  tenant: AccountItem | null;
}

const authState: AuthState = {
  isAuthorized: false,
  user: null,
  userRoles: null,
  restoringUsername: "",
  tenant: null,
};

type AuthData = {
  login: string;
  password: string;
};

export default {
  namespaced: true,

  state: authState,

  mutations: {
    updateIsAuthorized(state: AuthState, isAuthorized: boolean): void {
      state.isAuthorized = isAuthorized;
    },

    updateUser(state: AuthState, user: CognitoUserExt): void {
      state.user = user;
    },

    updateRestoringUsername(state: AuthState, username: string): void {
      state.restoringUsername = username;
    },

    updateUserRoles(state: AuthState, val: string[] | null): void {
      state.userRoles = val;
    },

    updateTenant(state: AuthState, val: AccountItem) {
      state.tenant = val;
    },
  },

  actions: {
    async authorize(ctx: ActionContext<AuthState, RootState>, authData: AuthData): Promise<string> {
      try {
        const { login, password } = authData;
        const user = await Auth.signIn(login, password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          ctx.commit("updateUser", user);
          return "new_password_required";
        } else {
          await ctx.dispatch("fetchUser");
          ctx.commit("updateIsAuthorized", true);
          return "success";
        }
      } catch (e) {
        console.error("Authorization error: ", e);
        ctx.commit("updateIsAuthorized", false);
        return "error";
      }
    },

    async fetchUser(ctx: ActionContext<AuthState, RootState>): Promise<void> {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const expires = user.getSignInUserSession().getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000);
        ctx.state.userRoles = user.getSignInUserSession().getIdToken().payload?.["cognito:groups"] || null;

        setTimeout(async () => {
          await ctx.dispatch("fetchUser");
        }, expires * 1000);

        ctx.commit("updateUser", user);
        ctx.commit("updateIsAuthorized", true);
      } catch (e) {
        console.error("Error fetch user: ", e);
        ctx.commit("updateUser", null);
        ctx.commit("updateIsAuthorized", false);
      }
    },

    async userLogout(ctx: ActionContext<AuthState, RootState>): Promise<void> {
      store.commit("app/cancelRequests");
      await Auth.signOut();
      ctx.commit("updateIsAuthorized", false);
      ctx.commit("updateUser", null);
      ctx.commit("updateUserRoles", null);
      ctx.commit("updateTenant", null);
      localStorage.removeItem(LOCALSTORAGE_TENANT);
      if (router.currentRoute.path !== (ROUTES.LOGIN.PATH || "/")) {
        await router.push(ROUTES.LOGIN.PATH);
      }
      store.commit("userSettings/resetUserSettings");
      store.commit("catalogs/resetCatalog");
    },
  },

  getters: {
    user(state: AuthState): CognitoUserExt | null {
      return state.user;
    },

    userRole(state: AuthState): string | null {
      const realRoles = userRoles.filter((role) => state.userRoles?.includes(role.name));
      return realRoles?.at(0)?.name || null;
    },

    restoringUsername(state: AuthState): string | null {
      return state.restoringUsername;
    },

    isAuthorized(state: AuthState): boolean {
      return state.isAuthorized;
    },

    tenant(state: AuthState): AccountItem | null {
      const localStorageValue = localStorage.getItem(LOCALSTORAGE_TENANT)
        ? JSON.parse(localStorage.getItem(LOCALSTORAGE_TENANT) as string)
        : null;
      return state.tenant ?? localStorageValue;
    },
  },
};
