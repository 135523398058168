import {
  compactFormat,
  dimension,
  dimensionSelector,
  legendPosition,
  palette,
  primaryMetric,
  primaryMetricSelector,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.RELATIONSHIPS],
  recommendedFamily: [FAMILY.RELATIONSHIPS],
  title: "Bubble Chart",
  defaultHeight: 6,
  controls: [dimension, { ...primaryMetric, metricName: "xAxis" }],
  settings: {
    palette,
    showItems: { ...showItems, gridPosition: [3, 0] },
    legendPosition: { ...legendPosition, gridPosition: [4, 0] },
    compactFormat: { ...compactFormat, gridPosition: [5, 0] },
    xAxis: {
      default: "ctr",
      type: "select",
      label: "Horizontal Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 2,
    },
    yAxis: {
      default: "cpm",
      type: "select",
      label: "Vertical Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
      tooltipOrder: 3,
    },
    decompositionMetric: {
      default: "channel",
      type: "select",
      label: "Decomposition Metric (bubble color)",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    displayMetric: {
      default: "spend",
      type: "select",
      label: "Display Metric (bubble size)",
      dictionary: "displayMetrics",
      gridPosition: [0, 1],
      tooltipOrder: 1,
    },
    xAxisTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [1, 1],
      forAxis: "xAxis",
    },
    yAxisTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis Title",
      gridPosition: [2, 1],
      forAxis: "yAxis",
    },
    dimensionSelector: { ...dimensionSelector, gridPosition: [6, 0] },
    primaryMetricSelector: { ...primaryMetricSelector, gridPosition: [7, 0] },
  },
};
