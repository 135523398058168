type Route = {
  PATH: string;
  MENU_NAME?: string;
  NAME: string;
};

export interface RoutesType {
  [key: string]: Route;
}

const ROUTES: RoutesType = {
  DASHBOARD: {
    PATH: "/dashboard",
    NAME: "dashboard",
  },

  ACCOUNT_MANAGEMENT: {
    PATH: "/account-management",
    NAME: "account-management",
  },

  USER_MANAGEMENT: {
    PATH: "/user-management",
    NAME: "user-management",
  },

  PLATFORM: {
    PATH: "/platform",
    NAME: "platform",
  },

  SETTINGS: {
    PATH: "/settings",
    NAME: "settings",
  },

  LOGIN: {
    PATH: "/login",
    NAME: "login",
  },

  RESET_PASSWORD: {
    PATH: "/reset-password",
    NAME: "reset-password",
  },

  RESTORE_PASSWORD: {
    PATH: "/restore-password",
    NAME: "restore-password",
  },

  CONFIRM_NEW_PASSWORD: {
    PATH: "/confirm-new-password",
    NAME: "confirm-new-password",
  },

  CREATE_NEW_PAGE: {
    PATH: "/create-new-page",
    NAME: "create-new-page",
  },

  EDIT_PAGE: {
    PATH: "/edit-page",
    NAME: "edit-page",
  },

  ADDITIONAL_DETAILS: {
    PATH: "/additional-details",
    NAME: "additional-details",
  },

  TEMPLATE_MANAGEMENT: {
    PATH: "/template-management",
    NAME: "template-management",
  },

  NOT_FOUND: {
    PATH: "/not-found",
    NAME: "notFound",
  },

  GLOSSARY: {
    PATH: "/glossary",
    NAME: "glossary",
  },
};

export default ROUTES;

export const NO_AUTH_PATHS = [
  ROUTES.NOT_FOUND.PATH,
  ROUTES.LOGIN.PATH,
  ROUTES.RESET_PASSWORD.PATH,
  ROUTES.RESTORE_PASSWORD.PATH,
  ROUTES.CONFIRM_NEW_PASSWORD.PATH,
];
