import buttons from "@/lang/buttons";
import datePresets from "@/lang/datePresets";
import dialogs from "@/lang/dialogs";
import filters from "@/lang/filters";
import formatters from "@/lang/formatters";
import inputs from "@/lang/inputs";
import legends from "@/lang/legends";
import links from "@/lang/links";
import messages from "@/lang/messages";
import placeholders from "@/lang/placeholders";
import roles from "@/lang/roles";
import routes from "@/lang/routes";
import tables from "@/lang/tables";
import titles from "@/lang/titles";
import tooltips from "@/lang/tooltips";

export default {
  locale: "en",
  ...titles,
  ...messages,
  ...links,
  ...buttons,
  ...inputs,
  ...routes,
  ...tables,
  ...roles,
  ...dialogs,
  ...datePresets,
  ...legends,
  ...formatters,
  ...placeholders,
  ...tooltips,
  ...filters,
};
