export type TypeAlert = "error" | "info" | "success";

interface Alerts {
  icon: string;
  className: string;
}

export const ALERTS_CONFIG: Record<TypeAlert, Alerts> = {
  success: {
    icon: "mdi-check",
    className: "success-alert",
  },
  error: {
    icon: "mdi-alert-circle-outline",
    className: "error-alert",
  },
  info: {
    icon: "mdi-information-outline",
    className: "info-alert",
  },
};

export const ALERTS_ACTIVE_TIME = 5000;
