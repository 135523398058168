import lineChartConfig from "@/settings/charts/lineChart";
import { granularity } from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME],
  name: "stepLineChart",
  title: "Step Line Chart",
  defaultHeight: 6,
  controls: [granularity],
  settings: lineChartConfig.settings,
};
