<template>
  <div class="custom-alert__wrapper" v-if="alert.isShow">
    <v-alert
      v-model="alert.isShow"
      dismissible
      transition="slide-y-reverse-transition"
      :class="'custom-alert ' + config[alert.type].className"
      :icon="config[alert.type].icon"
      close-icon="mdi-window-close"
      origin="bottom"
    >
      {{ alert.message }}
    </v-alert>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

import { ALERTS_ACTIVE_TIME, ALERTS_CONFIG } from "@/settings/alerts";

export default Vue.extend({
  name: "Alert",

  data() {
    return {
      timerId: null as null | ReturnType<typeof setTimeout>,
      config: ALERTS_CONFIG,
    };
  },

  computed: {
    ...mapGetters("app", ["alert"]),

    isShow: {
      get(): boolean {
        return this.alert.isShow;
      },
      set(val: boolean): void {
        if (!val) {
          this.$store.commit("app/hideAlert");
        }
      },
    },
  },

  methods: {
    ...mapMutations("app", ["hideAlert"]),
  },

  updated(): void {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      if (this.isShow) {
        this.hideAlert();
      }
    }, ALERTS_ACTIVE_TIME);
  },
});
</script>

<style lang="scss" scoped>
@import "@/scss/custom_variables.scss";

.custom-alert__wrapper {
  z-index: 1000;
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 96px;
}

.custom-alert {
  height: 56px;
  border-radius: $border-radius-x-small !important;
  padding: 16px 24px 20px;
  margin-bottom: 0;

  ::v-deep {
    .v-alert__dismissible {
      margin-left: 16px;
    }
  }
}

.success-alert {
  background-color: $color-positive !important;

  ::v-deep * {
    color: #ffffff !important;
    caret-color: #ffffff !important;
  }
}

.error-alert {
  background-color: $color-error !important;

  ::v-deep * {
    color: #ffffff !important;
    caret-color: #ffffff !important;
  }
}

.info-alert {
  background-color: $color-info !important;

  ::v-deep * {
    color: #222542 !important;
    caret-color: #222542 !important;
  }
}
</style>
