import { summaryRow } from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";

export default {
  family: [FAMILY.OTHER],
  recommendedFamily: [FAMILY.OTHER],
  name: "",
  title: "Data Table",
  defaultHeight: 8,
  widgetSettings: {
    widget: "DataTable",
  },
  settings: {
    columns: {
      default: [
        {
          title: "Campaign",
          value: "campaign",
        },
        {
          title: "Business Goal",
          value: "business_goal",
        },
        {
          title: "Channel",
          value: "channel",
        },
        {
          title: "Spend",
          value: "spend",
        },
        {
          title: "Impressions",
          value: "impressions",
        },
      ],
    },
    summaryRow: { ...summaryRow, gridPosition: [3, 0] },
    rowsPerPage: {
      default: 25,
      type: "select",
      label: "Rows Per Page",
      dictionary: "tableRows",
      gridPosition: [0, 0],
    },
    striped: {
      default: false,
      type: "checkbox",
      label: "Striped",
      gridPosition: [1, 0],
    },
    compactFormat: {
      default: false,
      type: "checkbox",
      label: "Compact Form",
      gridPosition: [2, 0],
    },
  },
};
