import { FAMILY } from "@/settings/widgetFamilies";

export const GRANULARITY_CONTROL_NAME = "granularity";
export const INVERSE_CONTROL_NAME = "inverse";
export const DIMENSION_SELECTOR_CONTROL_NAME = "dimensionSelector";
export const DIMENSION_CONTROL_NAME = "dimension";
export const PRIMARY_METRIC_CONTROL_NAME = "primaryMetric";
export const PRIMARY_METRIC_DEFAULT_NAME = "displayMetric";

export const palette = {
  default: 0,
};

export const legendPosition = {
  default: null,
  type: "legend",
  label: "Legend Position",
  dictionary: "legendPositions",
};

export const lineThickness = {
  default: 3,
  type: "select",
  label: "Line Thickness",
  dictionary: "lineThickness",
};

export const showItems = {
  default: 5,
  type: "select",
  label: "Show Items",
  dictionary: "showItems",
};

export const compactFormat = {
  default: false,
  type: "checkbox",
  label: "Compact Form",
};

export const dimensionSelector = {
  default: false,
  type: "checkbox",
  label: "Dimension Selector",
};

export const primaryMetricSelector = {
  default: false,
  type: "checkbox",
  label: "Primary Metric Selector",
};

export const summaryRow = {
  name: "summary",
  default: false,
  type: "checkbox",
  label: "Summary Row",
};

/* Controls */

export const inverse = {
  name: INVERSE_CONTROL_NAME,
  default: false,
  type: "switch",
  label: "Inverse",
  families: [FAMILY.COMPARISON, FAMILY.DECOMPOSITION],
};

export const granularity = {
  name: GRANULARITY_CONTROL_NAME,
  default: "month",
  type: "select",
  label: "Granularity",
  dictionary: "timeMetrics",
  families: [FAMILY.OVERTIME],
};

export const dimension = {
  name: DIMENSION_CONTROL_NAME,
  default: "",
  type: "select",
  label: "Dimension",
  dictionary: "dimensionMetrics",
  metricName: "decompositionMetric",
  families: [FAMILY.RELATIONSHIPS, FAMILY.COMPARISON, FAMILY.DECOMPOSITION, FAMILY.DISTRIBUTION, FAMILY.OVERTIME],
};

export const primaryMetric = {
  name: PRIMARY_METRIC_CONTROL_NAME,
  default: "",
  type: "select",
  label: "Primary Metric",
  dictionary: "displayMetrics",
  metricName: PRIMARY_METRIC_DEFAULT_NAME,
  families: [FAMILY.RELATIONSHIPS, FAMILY.COMPARISON, FAMILY.DECOMPOSITION, FAMILY.DISTRIBUTION, FAMILY.OVERTIME],
};

export const allControls = [inverse, granularity, summaryRow, dimension, primaryMetric];
