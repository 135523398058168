export default {
  close: "Close",
  collapsePanel: "Collapse panel",
  expandPanel: "Expand panel",
  goBack: "Go back",
  goBackToPageView: "Go back to the page view",
  edit: "Edit",
  loadReport: "Load report",
  enable: "Enable",
  disable: "Disable",
  resendInvite: "Resend invite",
  addRemoveFilters: "Add/remove filters",
  configureWidget: "Configure widget",
  removeWidget: "Remove widget",
  removeAddedWidget: (widgetTitle: string) => `Remove '${widgetTitle}'`,
  cancelPageCreation: "Cancel page creation",
  cancelUpdatingTemplate: "Cancel updating template",
  csvExport: "Export data to CSV",
  createPage: "Create page",
  deleteTemplate: "Delete the template",
  exportToPdf: "Export to PDF",
};
