import boxPlot from "@/settings/charts/boxPlot";
export default {
  ...boxPlot,
  title: "Box Plot Horizontal",
  settings: {
    ...boxPlot.settings,
    displayMetric: { ...boxPlot.settings.displayMetric, label: "Display Metric (Horizontal Axis)" },
    decompositionMetric: { ...boxPlot.settings.decompositionMetric, label: "Decomposition Metric (Vertical Axis)" },
    displayMetricTitle: { ...boxPlot.settings.displayMetricTitle, label: "Horizontal Axis Title" },
    decompositionMetricTitle: { ...boxPlot.settings.decompositionMetricTitle, label: "Vertical Axis Title" },
  },
};
