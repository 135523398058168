import Lang from "@/lang";

export const AWS_SERVICE_NAME = process.env.AWS_SERVICE_NAME;

type ApiRoute = {
  PATH: string;
};

export const API_ROUTES: Record<string, ApiRoute> = {
  AGENCIES: {
    PATH: "/agencies",
  },

  TENANTS: {
    PATH: "/tenants",
  },

  USERS: {
    PATH: "/users",
  },

  USER_SETTINGS: {
    PATH: "/user-settings",
  },

  RESET_PASSWORD: {
    PATH: "/reset-password",
  },

  REPORTS: {
    PATH: "/reports",
  },
  REPORT: {
    PATH: "/report",
  },

  CATALOG: {
    PATH: "/catalog",
  },

  SUMMARY: {
    PATH: "/summary",
  },

  TEMPLATES: {
    PATH: "/templates",
  },
};

export const apiErrorCodes: { code: number; message: string }[] = [
  {
    code: 1003,
    message: Lang.userAlreadyExists,
  },
  {
    code: 1002,
    message: Lang.emptyTenantsList,
  },
];

export const apiErrorStatuses: { status: number; message: string }[] = [{ status: 404, message: Lang.notFound }];

export const requestTokens = {
  apiGetSummary: "api_get_summary",
  apiGetChartData: "api_get_chart_data",
  apiCatalogs: "api_catalogs",
  apiGetUserSettings: "api_get_user_settings",
  apiSetDefaultUserSettings: "api_set_default_user_settings",
  apiGetTableData: "api_get_table_data",
  apiAddNewPage: "api_add_new_page",
  apiUpdatePage: "api_update_page",
  apiDeleteUserSettings: "api_delete_user_settings",
  apiServiceGeneralRequest: "api_service_general_request",
  apiServiceLoadReport: "api_service_load_report",
  apiCreateTenant: "api_create_tenant",
  apiEditTenant: "api_edit_tenant",
  apiGetTenants: "api_get_tenants",
  apiDisableAccount: "api_disable_account",
  apiGetAgencies: "api_get_agencies",
  apiGetTenantById: "api_get_tenant_by_id",
  apiGetUsers: "api_get_users",
  apiGetTenantsFromUserManagement: "api_get_tenants_from_user_management",
  apiEnableUser: "api_enable_user",
  apiDisableUser: "api_disable_user",
  apiSaveUser: "api_save_user",
  apiUpdateUser: "api_update_user",
  apiDelTemplate: "api_del_template",
  apiCreateTemplate: "api_create_template",
  apiUpdateTemplate: "api_update_template",
  apiGetTemplates: "api_get_templates",
  apiResetPassword: "api_reset_password",
};

export const requestStatusesDictionary: { [key: string]: string } = {
  sent: "sent",
  answered: "answered",
  error: "error",
};

export type RequestStatusDictionary = "sent" | "answered" | "error";
export type RequestMethods = "get" | "post" | "patch" | "put" | "del";
