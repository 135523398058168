<template>
  <div :class="['round-loader-container', transparentBackground ? 'round-loader-container_bg-transparent' : '']">
    <Loader :style="`scale: ${scale}`" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import Loader from "@/assets/icons/spinner-loader.vue";

export default Vue.extend({
  name: "SpinnerLoader",
  components: { Loader },

  props: {
    scale: {
      type: Number,
      default: 1,
    },

    transparentBackground: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.round-loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-application.theme--light {
  .round-loader-container {
    background: rgba(223, 225, 239, 0.64);

    ::v-deep {
      svg path {
        fill: #222542;
      }
    }
  }
}

.v-application.theme--dark {
  .round-loader-container {
    background: rgba(13, 14, 27, 0.64);

    ::v-deep {
      svg path {
        fill: #ffffff;
      }
    }
  }
}

.v-application.theme--dark .round-loader-container_bg-transparent,
.v-application.theme--light .round-loader-container_bg-transparent {
  background: transparent;
}
</style>
