class CleanupService {
  static escapeString(value: string) {
    return value.replace(/'/g, "''");
  }

  static escapeStringArray(values: string[]): string[] {
    return values.map(this.escapeString);
  }

  static cleanByKey(obj: Record<string, unknown>, keys?: string | string[]): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    const keysArray = typeof keys === "string" ? [keys] : keys;

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (!keysArray || keysArray.includes(key)) {
        if (typeof value === "string") {
          result[key] = this.escapeString(value);
        } else if (Array.isArray(value) && value.every((item) => typeof item === "string")) {
          result[key] = this.escapeStringArray(value as string[]);
        } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          result[key] = this.cleanByKey(value as Record<string, unknown>, keysArray);
        } else {
          result[key] = value;
        }
      } else {
        result[key] = value;
      }
    });

    return result;
  }

  static cleanByType(obj: Record<string, unknown>): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (typeof value === "string") {
        result[key] = this.escapeString(value);
      } else if (Array.isArray(value) && value.every((item) => typeof item === "string")) {
        result[key] = this.escapeStringArray(value as string[]);
      } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        result[key] = this.cleanByType(value as Record<string, unknown>);
      } else {
        result[key] = value;
      }
    });

    return result;
  }
}

export default CleanupService;
