import {
  compactFormat,
  dimension,
  dimensionSelector,
  granularity,
  legendPosition,
  lineThickness,
  palette,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME, FAMILY.COMPARISON],
  recommendedFamily: [FAMILY.OVERTIME],
  name: "comboColumnLineChart",
  title: "Combo Column & Line Chart",
  defaultHeight: 6,
  controls: [{ ...dimension, metricName: "xAxis" }, granularity],
  settings: {
    palette,
    lineThickness: { ...lineThickness, gridPosition: [3, 0], default: 2 },
    showItems: { ...showItems, gridPosition: [4, 0] },
    legendPosition: { ...legendPosition, gridPosition: [5, 0] },
    compactFormat: { ...compactFormat, gridPosition: [6, 0] },
    xAxis: {
      default: "channel",
      type: "select",
      label: "Horizontal Axis",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    yAxisPrimary: {
      default: "spend",
      type: "select",
      label: "Display Metric 1 (Bars)",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },
    yAxisSecondary: {
      default: "impressions",
      type: "select",
      label: "Display Metric 2 (Line)",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
      tooltipOrder: 2,
    },
    xAxisTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [0, 1],
      forAxis: "xAxis",
    },
    yAxisPrimaryTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis 1 Title",
      gridPosition: [1, 1],
      forAxis: "yAxisPrimary",
    },
    yAxisSecondaryTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis 2 Title",
      gridPosition: [2, 1],
      forAxis: "yAxisSecondary",
    },
  },
  familySettings: {
    [FAMILY.OVERTIME]: {
      xAxis: {
        default: "month",
        type: "select",
        label: "Horizontal Axis",
        dictionary: "timeMetrics",
        apiParameter: "dimension",
        gridPosition: [0, 0],
        tooltipOrder: 0,
      },
      showItems: undefined,
      legendPosition: { ...legendPosition, gridPosition: [4, 0] },
      compactFormat: { ...compactFormat, gridPosition: [5, 0] },
    },
    [FAMILY.COMPARISON]: {
      dimensionSelector: { ...dimensionSelector, gridPosition: [7, 0] },
    },
  },
};
