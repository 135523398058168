import {
  compactFormat,
  granularity,
  legendPosition,
  lineThickness,
  palette,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME],
  recommendedFamily: [FAMILY.OVERTIME],
  name: "lineSeriesChart",
  title: "Line Series Chart",
  defaultHeight: 6,
  controls: [granularity],
  settings: {
    palette,
    lineThickness: { ...lineThickness, gridPosition: [3, 0] },
    showItems: { ...showItems, gridPosition: [4, 0] },
    legendPosition: { ...legendPosition, gridPosition: [5, 0] },
    compactFormat: { ...compactFormat, gridPosition: [6, 0] },
    xAxis: {
      default: "month",
      type: "select",
      label: "Horizontal Axis Metric",
      dictionary: "timeMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    yAxis: {
      default: "spend",
      type: "select",
      label: "Vertical Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },
    decompositionMetric: {
      default: "channel",
      type: "select",
      label: "Decomposition Metric",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [2, 0],
      tooltipOrder: 2,
    },
    xAxisTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [0, 1],
      forAxis: "xAxis",
    },
    yAxisTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis Title",
      gridPosition: [1, 1],
      forAxis: "yAxis",
    },
  },
};
