import {
  compactFormat,
  dimension,
  dimensionSelector,
  legendPosition,
  palette,
  primaryMetric,
  primaryMetricSelector,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";

export const waffleDefaults = { xDimension: 16, yDimension: 16 };

export default {
  family: [FAMILY.DISTRIBUTION, FAMILY.DECOMPOSITION],
  title: "Waffle Chart",
  defaultHeight: 6,
  controls: [dimension, primaryMetric],

  settings: {
    palette,
    showItems: { ...showItems, gridPosition: [2, 0] },
    legendPosition: { ...legendPosition, gridPosition: [3, 0] },
    compactFormat: { ...compactFormat, gridPosition: [4, 0] },
    decompositionMetric: {
      default: "industry",
      type: "select",
      label: "Decomposition Metric",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    displayMetric: {
      default: "impressions",
      type: "select",
      label: "Display Metric",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },
    xDimension: {
      default: waffleDefaults.xDimension,
      type: "select",
      label: "Horizontal Bricks",
      dictionary: "waffleBricks",
      gridPosition: [0, 1],
    },
    yDimension: {
      default: waffleDefaults.yDimension,
      type: "select",
      label: "Vertical Bricks",
      dictionary: "waffleBricks",
      gridPosition: [1, 1],
    },
    dimensionSelector: { ...dimensionSelector, gridPosition: [5, 0] },
    primaryMetricSelector: { ...primaryMetricSelector, gridPosition: [6, 0] },
  },
};
