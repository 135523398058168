import Lang from "@/lang";

type ItemType = {
  text: string;
  value: string[] | null;
};

export type Filter = {
  label: string;
  filterName: string;
  filterKey: string;
  apiKey: string;
  value: string[] | null;
  items?: Array<ItemType>;
  isPageDetails?: boolean;
  default?: string | number | string[] | null;
  searchBar?: boolean;
};

export const campaignBudget = [
  {
    text: Lang.all,
    value: null,
  },
  {
    text: Lang.underValue("$500k"),
    value: {
      max: 500000,
    },
  },
  {
    text: Lang.betweenValues("$500k", "$1M"),
    value: {
      min: 500000,
      max: 1000000,
    },
  },
  {
    text: Lang.betweenValues("$1M", "$3M"),
    value: {
      min: 1000000,
      max: 3000000,
    },
  },
  {
    text: Lang.betweenValues("$3M", "$5M"),
    value: {
      min: 3000000,
      max: 5000000,
    },
  },
  {
    text: Lang.betweenValues("$5M", "$10M"),
    value: {
      min: 5000000,
      max: 10000000,
    },
  },
  {
    text: Lang.betweenValues("$10M", "$20M"),
    value: {
      min: 10000000,
      max: 20000000,
    },
  },
  {
    text: Lang.aboveValue("$20M"),
    value: {
      min: 20000000,
    },
  },
];

export const dashboardFilters: Array<Filter> = [
  {
    label: "Date Range",
    filterName: "DateRangeFilter",
    filterKey: "dateRange",
    apiKey: "dateRange",
    value: null,
    isPageDetails: true,
    default: "last12Months",
  },
  {
    label: "Industry",
    filterName: "MultipleSelectFilter",
    filterKey: "industry",
    apiKey: "industry",
    value: null,
    isPageDetails: true,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Business Goal",
    filterName: "MultipleSelectFilter",
    filterKey: "businessGoal",
    apiKey: "business_goal",
    value: null,
    isPageDetails: true,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Campaign KPI",
    filterName: "MultipleSelectFilter",
    filterKey: "campaignKpi",
    apiKey: "campaign_kpi",
    value: null,
    isPageDetails: true,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Channel",
    filterName: "MultipleSelectFilter",
    filterKey: "channel",
    apiKey: "channel",
    value: null,
    isPageDetails: true,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Agency",
    filterName: "MultipleSelectFilter",
    filterKey: "agency",
    apiKey: "agency",
    value: null,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Advertiser",
    filterName: "MultipleSelectFilter",
    filterKey: "advertiser",
    apiKey: "advertiser",
    value: null,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Campaign",
    filterName: "MultipleSelectFilter",
    filterKey: "campaign",
    apiKey: "campaign_name",
    value: null,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Campaign ID",
    filterName: "MultipleSelectFilter",
    filterKey: "campaignId",
    apiKey: "campaign_id",
    value: null,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
  {
    label: "Campaign Budget",
    filterName: "MultipleSelectFilter",
    filterKey: "campaignBudget",
    apiKey: "budgets",
    value: null,
    items: [{ text: "All", value: null }],
    searchBar: true,
  },
];
