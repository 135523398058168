export type FiltersWithNull = Record<string, string[]>;

export interface FilterssState {
  filters: FiltersWithNull | null;
}

const filterState: FilterssState = {
  filters: null,
};

export default {
  namespaced: true,

  state: filterState,

  mutations: {
    updateFilters(state: FilterssState, filters: FiltersWithNull): void {
      state.filters = filters;
    },
  },

  actions: {},

  getters: {
    filters(state: FilterssState): FiltersWithNull | null {
      return state.filters;
    },
  },
};
