export const sidebar = { postfix: "SideBar", collapsedParam: "collapsed" };

export const gridStepHeight = 60;
export const gridMargin = 16;

export const CAPITAL_OTHER_VALUE = "Other";
export const LOWERCASE_OTHER_VALUE = "other";

export const otherValues = [LOWERCASE_OTHER_VALUE, CAPITAL_OTHER_VALUE];

export const loaders = {
  templateManagement: "templateManagement",
};

export const LOCALSTORAGE_TENANT = "tenant";
