<template>
  <v-app>
    <router-view></router-view>
    <Alert />
    <SpinnerLoader v-if="isAppLoading" />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";

import Alert from "@/components/Alert.vue";
import SpinnerLoader from "@/components/loaders/SpinnerLoader.vue";
import { USER_SETTINGS_VERSION } from "@/settings/userDefaultSettings";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VUE_APP_VERSION = require("../package.json").version;

export default Vue.extend({
  name: "App",

  beforeCreate() {
    this.$vuetify.theme.currentTheme["accent-global"] =
      this.$store.getters["auth/tenant"]?.props?.accentColor || this.$vuetify.theme.currentTheme["accent-global"];
  },

  data() {
    return {};
  },

  components: {
    Alert,
    SpinnerLoader,
  },

  computed: {
    ...mapGetters("catalogs", ["isLoading"]),
    ...mapGetters("app", ["isAppLoading"]),
  },

  beforeMount() {
    document
      .querySelector("html")
      ?.setAttribute("version", `app:v${VUE_APP_VERSION}/settings:v${USER_SETTINGS_VERSION}`);
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  // Fix for all autocomplete inputs. We need to make background and text color as we define it.
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
}
</style>

<style lang="scss">
@import "@/scss/theme.scss";
@import "@/scss/variables.scss";
@import "@/scss/custom_variables.scss";
@import "@/scss/custom_classes.scss";
@import "@/scss/custom_style.scss";
</style>
