import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import { Ripple } from "vuetify/lib/directives";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

const commonColors = {
  success: "#1BC574",
  warning: "#E8802C",
  positive: "#1BC574",
  error: "#F84949",

  "content-white": "#FFFFFF",
  "content-light": "#F8F8FA",
  "content-dark": "#222542",

  "background-white": "#FFFFFF",
  "background-light": "#3A3D61",

  "accent-cyan": "#B5DEF0",
  "accent-pink": "#F362E7",
  "accent-purple": "#444865",

  "accent-global": "#F362E7",
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      [THEMES.LIGHT]: {
        ...commonColors,

        primary: "#FFFFFF",
        secondary: "#222542",
        medium: "#F7F9FC",
        tooltip: "#3D3F58",

        "content-medium": "#70748F",

        "background-medium": "#EDF0F5",
        "background-dark": "#262945",
        "background-html": "#FFFFFF",

        "divider-dark": "#EAEAEC",
      },

      [THEMES.DARK]: {
        ...commonColors,

        primary: "#262945",
        secondary: "#F8F8FA",
        medium: "#333659",
        tooltip: "#222542",

        "content-medium": "#BEB9CF",

        "background-medium": "#333659",
        "background-dark": "#262945",
        "background-html": "#111220",

        "divider-dark": "#4E5069",
      },
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
});
