export default {
  last12Months: "Last 12 months",
  thisYear: "This year",
  thisQuarter: "This quarter",
  thisMonth: "This month",
  lastYear: "Last year",
  lastQuarter: "Last quarter",
  last2Quarters: "Last 2 quarters",
  lastMonth: "Last month",
  allTime: "All time",
};
