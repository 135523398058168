import {
  compactFormat,
  dimension,
  dimensionSelector,
  legendPosition,
  palette,
  primaryMetric,
  primaryMetricSelector,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.COMPARISON, FAMILY.DISTRIBUTION],
  title: "Box Plot",
  defaultHeight: 6,
  controls: [dimension, primaryMetric],

  settings: {
    palette,
    showItems: { ...showItems, gridPosition: [2, 0] },
    legendPosition: { ...legendPosition, gridPosition: [3, 0] },
    compactFormat: { ...compactFormat, gridPosition: [4, 0] },

    displayMetric: {
      default: "ctr",
      type: "select",
      label: "Display Metric (Vertical Axis)",
      dictionary: "displayMetrics",
      apiParameter: "statistic",
      isStatistic: true,
      gridPosition: [0, 0],
      tooltipOrder: 1,
    },

    decompositionMetric: {
      default: "channel",
      type: "select",
      label: "Decomposition Metric (Horizontal Axis)",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [1, 0],
      tooltipOrder: 0,
    },

    displayMetricTitle: {
      default: "",
      type: "text",
      label: "Vertical Axis Title",
      gridPosition: [0, 1],
      forAxis: "displayMetric",
    },

    decompositionMetricTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [1, 1],
      forAxis: "decompositionMetric",
    },

    dimensionSelector: { ...dimensionSelector, gridPosition: [5, 0] },
    primaryMetricSelector: { ...primaryMetricSelector, gridPosition: [6, 0] },
  },
};
