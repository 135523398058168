import Vue from "vue";
import Vuex from "vuex";

import app from "@/store/modules/app";
import auth from "@/store/modules/auth";
import catalogs from "@/store/modules/catalogs";
import charts from "@/store/modules/charts";
import dashboardsTemplates from "@/store/modules/dashboardsTemplates";
import filters from "@/store/modules/filters";
import pageEdit from "@/store/modules/pageEdit";
import userSettings from "@/store/modules/userSettings";

Vue.use(Vuex);

export interface RootState {
  appVersion: string;
}

export default new Vuex.Store({
  state: {
    appVersion: "",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    userSettings,
    app,
    catalogs,
    pageEdit,
    charts,
    dashboardsTemplates,
    filters,
  },
});
