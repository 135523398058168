export interface WidgetTabs {
  name: string;
  title: string;
  order: number;
}

export const FAMILY = {
  OVERTIME: "overtime",
  COMPARISON: "comparison",
  DISTRIBUTION: "distribution",
  DECOMPOSITION: "decomposition",
  RELATIONSHIPS: "relationships",
  OTHER: "other",
};

export const WIDGET_TAB_SPECIAL = {
  RECOMMENDED: "recommended",
  ALL: "all",
};

export const widgetFamilies: Record<string, WidgetTabs> = {
  [FAMILY.OVERTIME]: { name: FAMILY.OVERTIME, title: "Change Over Time", order: 2 },
  [FAMILY.COMPARISON]: { name: FAMILY.COMPARISON, title: "Comparison Between Groups", order: 3 },
  [FAMILY.DISTRIBUTION]: { name: FAMILY.DISTRIBUTION, title: "Data Distribution", order: 4 },
  [FAMILY.DECOMPOSITION]: { name: FAMILY.DECOMPOSITION, title: "Part-to-whole Decomposition", order: 5 },
  [FAMILY.RELATIONSHIPS]: { name: FAMILY.RELATIONSHIPS, title: "Relationships Between Variables", order: 6 },
  [FAMILY.OTHER]: { name: FAMILY.OTHER, title: "Other", order: 7 },
};

export const widgetTabs: Record<string, WidgetTabs> = {
  recommended: { name: WIDGET_TAB_SPECIAL.RECOMMENDED, title: "Recommended", order: 0 },
  all: { name: WIDGET_TAB_SPECIAL.ALL, title: "All", order: 1 },
  ...widgetFamilies,
};
