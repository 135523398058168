import { API } from "aws-amplify";
import { forIn } from "lodash";

import { REQUEST_CANCEL_MARK } from "@/api/Api";
import Lang from "@/lang";
import { TypeAlert } from "@/settings/alerts";

interface Alert {
  isShow: boolean;
  message?: string;
  type?: TypeAlert;
}

export interface RootState {
  alert: Alert;
  dataError: {
    isError: boolean;
    message: string;
  };
  requests: Record<string, Promise<unknown>>;
  disabledLoaders: string[];
}

export default {
  namespaced: true,
  state: {
    alert: {
      isShow: false,
      message: Lang.defaultAlertMessage.error,
      type: "error",
      locked: false,
    },
    requests: {},
    disabledLoaders: [],
  },

  mutations: {
    showAlert(state: RootState, props: { message?: string; type: TypeAlert }): void {
      const message = props.message ? props.message : Lang.defaultAlertMessage[props.type];

      state.alert = { ...props, message, isShow: true };
    },

    hideAlert(state: RootState): void {
      state.alert = { ...state.alert, isShow: false };
    },

    pushRequest(state: RootState, request: { token: string; promise: Promise<unknown> }): void {
      state.requests[request.token] = request.promise;
    },

    cancelRequests(state: RootState): void {
      forIn(state.requests, (req) => {
        API.cancel(req, REQUEST_CANCEL_MARK);
      });

      state.requests = {};
    },

    enableLoader(state: RootState, loaderName: string): void {
      const index = state.disabledLoaders.indexOf(loaderName);
      if (index > -1) {
        state.disabledLoaders.splice(index, 1);
      }
    },

    disableLoader(state: RootState, loaderName: string): void {
      const index = state.disabledLoaders.indexOf(loaderName);
      if (index < 0) {
        state.disabledLoaders.push(loaderName);
      }
    },
  },

  actions: {},

  getters: {
    alert(state: RootState): Alert {
      return state.alert;
    },

    isAppLoading(
      state: RootState,
      getters: { [key: string]: unknown },
      rootState: { [key: string]: unknown },
      rootGetters: { [key: string]: unknown }
    ): boolean {
      return (
        (rootGetters["catalogs/isLoading"] as boolean) || (rootGetters["userSettings/isLoadingUserSettings"] as boolean)
      );
    },

    requests(state: RootState): Record<string, Promise<unknown>> {
      return state.requests;
    },

    isLoaderEnabled: (state: RootState) => (loaderName: string) => {
      return state.disabledLoaders.indexOf(loaderName) < 0;
    },
  },
};
