import { RequestMethods } from "@/settings/apiRoutes";

interface ChartsState {
  noDataStatuses: NoDataStatuses;
  requestStatuses: RequestStatus[];
  reRenderingChartId: string;
}

interface NoDataStatuses {
  [key: string]: boolean;
}

export interface RequestStatus {
  timestamp: number;
  path: string;
  method: RequestMethods;
  token: string;
  status: "answered" | "error";
}

export interface NoDataStatusItem {
  widgetId: string;
  status: boolean;
}

const chartsState: ChartsState = {
  noDataStatuses: {},
  requestStatuses: [],
  reRenderingChartId: "",
};

export default {
  namespaced: true,
  state: chartsState,

  mutations: {
    updateNoDataStatus(state: ChartsState, statusItem: NoDataStatusItem): void {
      state.noDataStatuses = { ...state.noDataStatuses, [statusItem.widgetId]: statusItem.status };
    },

    updateRequestStatus(state: ChartsState, item: RequestStatus): void {
      const foundItem = state.requestStatuses.find((el) => el.timestamp === item.timestamp);
      if (foundItem) {
        foundItem.status = item.status;
      } else {
        state.requestStatuses.push(item);
      }
    },

    cleanRequestStatuses(state: ChartsState): void {
      state.requestStatuses = [];
    },

    startReRenderChart(state: ChartsState, id: string): void {
      state.reRenderingChartId = id;
    },

    stopReRenderChart(state: ChartsState): void {
      state.reRenderingChartId = "";
    },
  },

  actions: {},

  getters: {
    noDataStatuses: (state: ChartsState): NoDataStatuses => {
      return state.noDataStatuses;
    },

    requestStatuses: (state: ChartsState): RequestStatus[] => {
      return state.requestStatuses;
    },

    reRenderingChartId(state: ChartsState) {
      return state.reRenderingChartId;
    },
  },
};
