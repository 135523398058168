import ROUTES from "@/settings/routes";
import { userRoleNames } from "@/settings/userManagement";

export interface AclType {
  isDefault?: boolean;
  permissions?: string[];
}

export const aclSettings: Record<string, Record<string, Record<string, AclType>>> = {
  [userRoleNames.admins]: {
    pages: {
      [ROUTES.DASHBOARD.PATH]: { isDefault: true },
      [ROUTES.ACCOUNT_MANAGEMENT.PATH]: {},
      [ROUTES.USER_MANAGEMENT.PATH]: {},
      [ROUTES.PLATFORM.PATH]: {},
      [ROUTES.SETTINGS.PATH]: {},
      [ROUTES.CREATE_NEW_PAGE.PATH]: {},
      [ROUTES.EDIT_PAGE.PATH]: {},
      [ROUTES.TEMPLATE_MANAGEMENT.PATH]: {},
      // TODO: Glossary page is hidden for awhile.
      // [ROUTES.GLOSSARY.PATH]: {},
    },
  },
  [userRoleNames.armUsers]: {
    pages: {
      [ROUTES.DASHBOARD.PATH]: { isDefault: true },
      [ROUTES.CREATE_NEW_PAGE.PATH]: {},
      [ROUTES.EDIT_PAGE.PATH]: {},
      // TODO: Glossary page is hidden for awhile.
      // [ROUTES.GLOSSARY.PATH]: {},
    },
  },
  [userRoleNames.users]: {
    pages: {
      [ROUTES.DASHBOARD.PATH]: { isDefault: true },
      [ROUTES.CREATE_NEW_PAGE.PATH]: {},
      [ROUTES.EDIT_PAGE.PATH]: {},
      // TODO: Glossary page is hidden for awhile.
      // [ROUTES.GLOSSARY.PATH]: {},
    },
  },
};
