import { Amplify, Auth } from "aws-amplify";
import Vue from "vue";
import SvgSprite from "vue-svg-sprite";

import { AWS_SERVICE_NAME } from "@/settings/apiRoutes";
import { imageDictionary } from "@/settings/imageDictionary";
import { FAMILY } from "@/settings/widgetFamilies";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fontsource/work-sans";
import "@mdi/font/css/materialdesignicons.css";

const SPECIAL_IMAGE_FAMILY_NAMES = [FAMILY.OVERTIME];

Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID, // REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.VUE_APP_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.VUE_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID
  },
  API: {
    endpoints: [
      {
        name: AWS_SERVICE_NAME,
        endpoint: process.env.VUE_APP_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        },
      },
    ],
  },
});

Vue.config.productionTip = false;

Vue.use(SvgSprite, { url: require("@/assets/icons/sprite.svg"), class: "app-icon" });

Vue.mixin({
  methods: {
    getImageSrc: function (typeChart: string, family: string, wide?: "wide"): unknown {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      const aspectRatio = wide ? "wide" : "std";
      const assetPath = `assets/widgetImages`;
      const familyPath = SPECIAL_IMAGE_FAMILY_NAMES.includes(family) ? `${family}/` : "";
      const imageName = (imageDictionary[typeChart] || imageDictionary.default).image;

      try {
        return require(`@/${assetPath}/${theme}/${aspectRatio}/${familyPath}${imageName}`);
      } catch (e) {
        if (familyPath) {
          try {
            return require(`@/${assetPath}/${theme}/${aspectRatio}/${imageName}`);
          } catch (e) {
            return require(`@/${assetPath}/${theme}/${aspectRatio}/${imageDictionary.default.image}`);
          }
        }

        return require(`@/${assetPath}/${theme}/${aspectRatio}/${imageDictionary.default.image}`);
      }
    },
    getItemIcon: (chartType: string): string => {
      return imageDictionary[chartType]?.icon ?? imageDictionary.default.icon;
    },
  },
});

export const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
