import {
  compactFormat,
  dimension,
  dimensionSelector,
  inverse,
  legendPosition,
  palette,
  showItems,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.COMPARISON, FAMILY.DECOMPOSITION],
  recommendedFamily: [FAMILY.DECOMPOSITION],
  title: "Stacked Bar Chart",
  defaultHeight: 6,
  controls: [dimension, inverse],
  settings: {
    palette,
    showItems: { ...showItems, gridPosition: [3, 0] },
    legendPosition: { ...legendPosition, gridPosition: [4, 0] },
    compactFormat: { ...compactFormat, gridPosition: [5, 0] },
    xAxisName: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [2, 1],
      forAxis: "displayMetric",
    },
    yAxisName: {
      default: "",
      type: "text",
      label: "Vertical Axis Title 1",
      gridPosition: [0, 1],
      forAxis: "decompositionMetric",
    },
    yAxisSecondaryName: {
      default: "",
      type: "text",
      label: "Vertical Axis Title 2",
      gridPosition: [1, 1],
      forAxis: "decompositionMetricSecondary",
    },
    decompositionMetric: {
      default: "campaign_kpi",
      type: "select",
      label: "Decomposition Metric 1",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    decompositionMetricSecondary: {
      default: "channel",
      type: "select",
      label: "Decomposition Metric 2",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },
    displayMetric: {
      default: "spend",
      type: "select",
      label: "Display Metric",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
      tooltipOrder: 2,
    },
    dimensionSelector: { ...dimensionSelector, gridPosition: [6, 0] },
  },
};
