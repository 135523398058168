import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";

import { DATE_FORMAT } from "@/helpers/formatters";
import Lang from "@/lang";

type dateObject = {
  startDate: string;
  endDate: string;
};

export const datePresets = [
  {
    text: Lang.last12Months,
    value: "last12Months",
  },
  {
    text: Lang.thisYear,
    value: "thisYear",
  },
  {
    text: Lang.thisQuarter,
    value: "thisQuarter",
  },
  {
    text: Lang.thisMonth,
    value: "thisMonth",
  },
  {
    text: Lang.lastYear,
    value: "lastYear",
  },
  {
    text: Lang.lastQuarter,
    value: "lastQuarter",
  },
  {
    text: Lang.last2Quarters,
    value: "last2Quarters",
  },
  {
    text: Lang.lastMonth,
    value: "lastMonth",
  },
  {
    text: Lang.allTime,
    value: "allTime",
  },
];

export const setDate = (preset: string): dateObject => {
  const dateNow = new Date();

  switch (preset) {
    case "last12Months":
      return {
        startDate: format(startOfMonth(subMonths(dateNow, 12)), DATE_FORMAT),
        endDate: format(endOfMonth(subMonths(dateNow, 1)), DATE_FORMAT),
      };
    case "last2Quarters": {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 2)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 1)), DATE_FORMAT),
      };
    }
    case "thisYear":
      return {
        startDate: format(startOfYear(dateNow), DATE_FORMAT),
        endDate: format(endOfYear(dateNow), DATE_FORMAT),
      };
    case "lastYear": {
      const prevYear = subYears(startOfYear(dateNow), 1);
      return {
        startDate: format(prevYear, DATE_FORMAT),
        endDate: format(endOfYear(prevYear), DATE_FORMAT),
      };
    }
    case "thisQuarter":
      return {
        startDate: format(startOfQuarter(dateNow), DATE_FORMAT),
        endDate: format(endOfQuarter(dateNow), DATE_FORMAT),
      };
    case "lastQuarter": {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 1)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 1)), DATE_FORMAT),
      };
    }
    case "thisMonth":
      return {
        startDate: format(startOfMonth(dateNow), DATE_FORMAT),
        endDate: format(endOfMonth(dateNow), DATE_FORMAT),
      };
    case "lastMonth": {
      const thisMonth = startOfMonth(dateNow);
      const prevMonth = subMonths(thisMonth, 1);
      return {
        startDate: format(startOfMonth(prevMonth), DATE_FORMAT),
        endDate: format(endOfMonth(prevMonth), DATE_FORMAT),
      };
    }
    case "last7Days":
      return {
        startDate: format(sub(dateNow, { days: 7 }), DATE_FORMAT),
        endDate: format(dateNow, DATE_FORMAT),
      };
    case "last14Days":
      return {
        startDate: format(sub(dateNow, { days: 14 }), DATE_FORMAT),
        endDate: format(dateNow, DATE_FORMAT),
      };
    case "last30Days":
      return {
        startDate: format(sub(dateNow, { days: 30 }), DATE_FORMAT),
        endDate: format(dateNow, DATE_FORMAT),
      };
    case "allTime":
      return {
        startDate: "",
        endDate: "",
      };
    default: {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 2)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 1)), DATE_FORMAT),
      };
    }
  }
};

export const setPreviousDate = (preset: string): dateObject => {
  const dateNow = new Date();

  switch (preset) {
    case "last12Months":
      return {
        startDate: format(startOfMonth(subMonths(dateNow, 24)), DATE_FORMAT),
        endDate: format(endOfMonth(subMonths(dateNow, 13)), DATE_FORMAT),
      };
    case "last2Quarters": {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 4)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 3)), DATE_FORMAT),
      };
    }
    case "thisYear": {
      const prevYear = subYears(startOfYear(dateNow), 1);
      return {
        startDate: format(prevYear, DATE_FORMAT),
        endDate: format(endOfYear(prevYear), DATE_FORMAT),
      };
    }
    case "lastYear": {
      const prevYear = subYears(startOfYear(dateNow), 2);
      return {
        startDate: format(prevYear, DATE_FORMAT),
        endDate: format(endOfYear(prevYear), DATE_FORMAT),
      };
    }
    case "thisQuarter": {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 1)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 1)), DATE_FORMAT),
      };
    }
    case "lastQuarter": {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 2)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 2)), DATE_FORMAT),
      };
    }
    case "thisMonth": {
      const thisMonth = startOfMonth(dateNow);
      const prevMonth = subMonths(thisMonth, 1);
      return {
        startDate: format(startOfMonth(prevMonth), DATE_FORMAT),
        endDate: format(endOfMonth(prevMonth), DATE_FORMAT),
      };
    }
    case "lastMonth": {
      const thisMonth = startOfMonth(dateNow);
      const prevMonth = subMonths(thisMonth, 2);
      return {
        startDate: format(startOfMonth(prevMonth), DATE_FORMAT),
        endDate: format(endOfMonth(prevMonth), DATE_FORMAT),
      };
    }
    case "allTime":
      return {
        startDate: "",
        endDate: "",
      };
    default: {
      const currQuarter = startOfQuarter(dateNow);
      return {
        startDate: format(startOfQuarter(subQuarters(new Date(currQuarter), 4)), DATE_FORMAT),
        endDate: format(endOfQuarter(subQuarters(new Date(currQuarter), 3)), DATE_FORMAT),
      };
    }
  }
};
