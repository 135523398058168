export default {
  addNew: "Add New",
  addNewAccount: "Add New Account",
  addNewUser: "Add New User",
  cancel: "Cancel",
  save: "Save",
  editLayout: "Edit Layout",
  editUser: "Edit User",
  select: "Select",
  proceed: "Proceed",
  addNewPage: "Add New Page",
  addNewWidget: "Add New Widget",
  addWidget: "Add Widget",
  addNewMetric: "Add New Metric",
  skip: "Skip",
  applyFilters: "Apply",
  requestPasswordReset: "Request Password Reset",
  resend: "Resend",
  buttonResetPassword: "Reset Password",
  buttonSignIn: "Sign in",
  confirm: "Confirm",
  delete: "Delete",
  reset: "Reset",
  only: "Only",
  defaults: "Defaults",
  btnRemoveLogo: "Delete the logo",
};
