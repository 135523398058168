import { cloneDeep, startsWith } from "lodash";
import { v4 as uuid } from "uuid";

import updateSettings from "@/migrations/updateSettings";
import { THEMES } from "@/plugins/vuetify";
import defaultUserSettings, { USER_SETTINGS_VERSION } from "@/settings/userDefaultSettings";
import { userRoleNames } from "@/settings/userManagement";
import store from "@/store";
import { Dashboard, FilterState } from "@/store/modules/userSettings";

export interface Widget {
  h: number;
  i: string;
  w: number;
  x: number;
  y: number;
  minH?: number;
  moved?: boolean;
}

export interface WidgetContentConfig {
  title: string;
  widget: string;
  chartType: string;
  family: string;
  settings: Record<string, unknown>;
}

export interface WidgetConfig {
  grid: Widget;
  content: WidgetContentConfig;
}

export interface DashboardConfig {
  icon?: string;
  title: string;
  idTemplate?: string;
  filterState: FilterState;
  layout: Array<WidgetConfig>;
}

export interface UserSettingsConfig {
  modifiedAt?: number;
  userSettingsVersion?: string;
  dashboards: DashboardConfig[];
}

export default class UserSettingsManagement {
  public createDefaultSettings() {
    const { dashboards } = defaultUserSettings;
    const dashboardPrepared = dashboards.map((dashboard: DashboardConfig) => {
      const prepareLayout = dashboard.layout.map((layout: WidgetConfig) => {
        const prepareContent = this.prepareContentDefault(layout.content);
        return { ...layout, content: prepareContent };
      });
      return {
        ...dashboard,
        id: this.createId(dashboard.title),
        icon: dashboard.icon,
        isDefaultPage: true,
        layout: prepareLayout,
      };
    });

    const userRole = store.getters["auth/userRole"];
    const theme = userRole === userRoleNames.users ? THEMES.LIGHT : THEMES.DARK;

    return {
      ...defaultUserSettings,
      modifiedAt: Date.now(),
      userSettingsVersion: USER_SETTINGS_VERSION,
      dashboards: dashboardPrepared,
      theme,
    };
  }

  public getDefaultSettings(dashboard: Dashboard) {
    const { dashboards } = defaultUserSettings;
    const defaultSettings = dashboards.find((settings) => {
      return startsWith(dashboard.id, settings.idTemplate);
    });
    if (defaultSettings) {
      const prepareLayout = defaultSettings.layout.map((layout: WidgetConfig) => {
        const prepareContent = this.prepareContentDefault(layout.content);
        return { ...layout, content: prepareContent };
      });

      return {
        ...dashboard,
        title: defaultSettings.title,
        filterState: defaultSettings.filterState,
        layout: prepareLayout,
      };
    }
  }

  public prepareContentDefault(content: WidgetContentConfig) {
    return {
      ...content,
      id: this.createId(content.title),
    };
  }

  public createId(name: string): string {
    return `${name
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "-")}-${uuid()}`;
  }

  public compareVersion(oldVersion: string, newVersion: string): boolean {
    const compare = oldVersion.localeCompare(newVersion, undefined, { numeric: true, sensitivity: "base" });
    return compare < 0;
  }

  public updateSettings(currentSettings: UserSettingsConfig) {
    let settings = cloneDeep(currentSettings);
    try {
      settings = updateSettings.update(settings);
      settings.userSettingsVersion = updateSettings.version;
      settings.modifiedAt = Date.now();
    } catch (e) {
      // TODO: Here you need to display an update error notification.
      console.error(`Failure to perform an update, current version ${settings.userSettingsVersion}: ${e}`);
    }

    return settings;
  }
}
