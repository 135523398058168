import { defaultColorSet } from "@/settings/colors";
import dimensionMetrics from "@/settings/metrics/dimensionMetrics";
import displayMetrics from "@/settings/metrics/displayMetrics";
import timeMetrics from "@/settings/metrics/timeMetrics";
import { tableRows } from "@/settings/tableSettings";
import { InputDictionary } from "@/types/common";

export default <Record<string, InputDictionary[]>>{
  legendPositions: [
    { title: "Bottom", value: "bottom" },
    { title: "Top", value: "top" },
    { title: "Left", value: "left" },
    { title: "Right", value: "right" },
  ],
  frequency: [
    { title: "Year", value: "year" },
    { title: "Quarter", value: "quarter" },
    { title: "Month", value: "month" },
    { title: "Week", value: "week" },
    { title: "Date", value: "date" },
  ],

  summaryTrendOptions: [
    { title: "Period over period", value: "pop" },
    { title: "Year over year", value: "yoy" },
  ],

  colors: defaultColorSet.map((value) => ({ title: value, value })),

  percents: [
    { title: "10%", value: 10 },
    { title: "20%", value: 20 },
    { title: "30%", value: 30 },
    { title: "40%", value: 40 },
    { title: "50%", value: 50 },
    { title: "60%", value: 60 },
    { title: "70%", value: 70 },
    { title: "80%", value: 80 },
    { title: "90%", value: 90 },
  ],

  lineThickness: [
    { title: "1", value: 1 },
    { title: "2", value: 2 },
    { title: "3", value: 3 },
    { title: "4", value: 5 },
  ],

  waffleBricks: [
    { title: "4", value: 4 },
    { title: "8", value: 8 },
    { title: "16", value: 16 },
    { title: "32", value: 32 },
  ],

  showItems: [
    { title: "All", value: null },
    { title: "Top 5", value: 5 },
    { title: "Top 8", value: 8 },
    { title: "Top 10", value: 10 },
  ],

  dimensionMetrics,
  displayMetrics,
  nullableDisplayMetrics: [
    {
      title: "",
      value: null,
    },
    ...displayMetrics,
  ],
  timeMetrics,
  summaryMetrics: displayMetrics,

  tableRows,
};
