import { format } from "date-fns";
import { getName, registerLocale } from "i18n-iso-countries";

import { DEFAULT_DECIMALS } from "@/helpers/constants";
import Lang from "@/lang";

// eslint-disable-next-line @typescript-eslint/no-var-requires
registerLocale(require("i18n-iso-countries/langs/en.json"));

// This variable defines the compact format that is used.
// We still have the standard compact format here and can switch to it by the customer request.
const IS_CUSTOM_COMPACT = true;

export const DATE_FORMAT = "yyyy-MM-dd";
export const VISUAL_DATE_FORMAT = "MM-dd-yyyy";
export const VISUAL_MONTH_FORMAT = "MMM yy";
export const VISUAL_QUARTER_FORMAT = "'Q'q yy";

export const stringOrNumberToDate = (value: string | number): Date => {
  if (typeof value === "string") {
    try {
      const parts = value.match(/^(\d{1,4})-(\d{1,2})-(\d{1,2})/);

      if (parts) {
        return new Date(parseInt(parts[1], 10), parseInt(parts[2], 10) - 1, parseInt(parts[3], 10));
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return new Date(value);
};

const parseNumber = (
  val: number,
  config: Intl.NumberFormatOptions
): { integer: string; fraction: string; compact: string; unit: string; currency: string } => {
  const parts = new Intl.NumberFormat("en-NY", { ...config, notation: "compact" }).formatToParts(val as number);

  const prepared = parts.reduce((prev, curr) => {
    prev[curr.type] = curr.value;

    return prev;
  }, {} as Record<string, string>);

  return {
    integer: prepared?.integer || "0",
    fraction: prepared?.fraction || "0",
    compact: prepared?.compact || "",
    unit: prepared?.unit || "",
    currency: prepared?.currency || "",
  };
};

const compactFormatter = (val: number | null, config: Intl.NumberFormatOptions): string => {
  // Initially do not use config settings for fractions.
  const preparedConfig = {
    ...config,
    minimumFractionDigits: undefined,
    maximumFractionDigits: undefined,
  };

  // If the value is empty, just format `0` with the provided config.
  if (val === null || val === 0) {
    return new Intl.NumberFormat("en-NY", preparedConfig).format(0);
  }

  // Disassemble value using the 'parseNumber' function with a default maximum fraction digits setting.
  let parts = parseNumber(val, { ...preparedConfig, maximumFractionDigits: DEFAULT_DECIMALS });

  // If the integer part is 0, format the value using compact notation and return it
  // We use config?.maximumFractionDigits to overwrite preparedConfig declaration.
  if (Number(parts.integer) === 0) {
    return new Intl.NumberFormat("en-NY", {
      ...preparedConfig,
      notation: "compact",
      minimumFractionDigits: config?.maximumFractionDigits ?? DEFAULT_DECIMALS,
      // TODO: Check the next string changes for ACF-428(tooltip precision).
      maximumFractionDigits: config?.maximumFractionDigits ?? DEFAULT_DECIMALS,
    }).format(val as number);
  }

  // TODO: Check the reason of this code block.
  // Check if the number is round (e.g., 1000) and disassemble it accordingly using 'parseNumber'.
  const isRound = Math.round(Number(`${parts.integer}.${parts.fraction}`)) === 1000;
  parts = parseNumber(val, {
    ...preparedConfig,
    maximumFractionDigits: isRound || config?.maximumFractionDigits === 0 ? 0 : 1,
  });

  // If the integer part is between 1 and 10, format the number using the parsed parts.
  if (Number(parts.integer) >= 1 && Number(parts.integer) < 10 && Number(parts.fraction) !== 0) {
    return `${parts.currency}${parts.integer}.${parts.fraction}${parts.compact}${parts.unit}`;
  }

  // If the integer part is 10 or above, or the fraction part is equal to zero,
  // format the number without fractions using the parsed parts.
  parts = parseNumber(val, { ...preparedConfig, maximumFractionDigits: 0 });
  return `${parts.currency}${parts.integer}${parts.compact ?? ""}${parts.unit}`;
};

export const configNumberFormatter: Record<string, Intl.NumberFormatOptions> = {
  currencyFormat: {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: DEFAULT_DECIMALS,
    maximumFractionDigits: DEFAULT_DECIMALS,
    notation: undefined,
  },
  integerCurrencyFormat: {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    notation: undefined,
  },
  percentFormat: {
    style: "percent",
    minimumFractionDigits: DEFAULT_DECIMALS,
    maximumFractionDigits: DEFAULT_DECIMALS,
    notation: undefined,
  },
  integerPercentFormat: { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 0, notation: undefined },
  integerFormat: { minimumFractionDigits: 0, maximumFractionDigits: 0, notation: undefined },
  numberFixedFormat: {
    minimumFractionDigits: DEFAULT_DECIMALS,
    maximumFractionDigits: DEFAULT_DECIMALS,
    notation: undefined,
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// Wide variants of config types.
type IFormatters = Record<string, (val: string | number | null, config?: any) => string | number>;

const formatDate = (value: string | number | null, dateFormat = VISUAL_DATE_FORMAT): string | number => {
  if (value === undefined || value === "") {
    return "";
  }

  if (value === null) {
    return Lang.null;
  }

  const date = stringOrNumberToDate(value);
  return date ? format(date, dateFormat) : value;
};

export const formatters: IFormatters = {
  codeToCountry: (val) => {
    if (val === null) {
      return Lang.unknown;
    }

    return getName(val, Lang.locale, { select: "alias" }) || Lang.unknown;
  },

  currencyFormat: (val, config: Intl.NumberFormatOptions): string => {
    if (typeof val !== "number" && val !== null) {
      return val;
    }

    return config?.notation === "compact" && IS_CUSTOM_COMPACT
      ? compactFormatter(val, { ...configNumberFormatter.currencyFormat, ...config })
      : Intl.NumberFormat("en-NY", { ...configNumberFormatter.currencyFormat, ...config }).format(val as number);
  },

  integerCurrencyFormat: (val, config: Intl.NumberFormatOptions): string => {
    if (typeof val !== "number" && val !== null) {
      return val;
    }

    return config?.notation === "compact" && IS_CUSTOM_COMPACT
      ? compactFormatter(val, { ...configNumberFormatter.integerCurrencyFormat, ...config })
      : new Intl.NumberFormat("en-NY", { ...configNumberFormatter.integerCurrencyFormat, ...config }).format(
          val as number
        );
  },

  percentFormat: (val, config: Intl.NumberFormatOptions): string => {
    if (typeof val !== "number" && val !== null) {
      return val;
    }

    return config?.notation === "compact" && IS_CUSTOM_COMPACT
      ? compactFormatter(val, { ...configNumberFormatter.percentFormat, ...config })
      : new Intl.NumberFormat("en-NY", { ...configNumberFormatter.percentFormat, ...config }).format(val as number);
  },

  integerPercentFormat: (val, config: Intl.NumberFormatOptions): string => {
    if (typeof val !== "number" && val !== null) {
      return val;
    }

    return config?.notation === "compact" && IS_CUSTOM_COMPACT
      ? compactFormatter(val, { ...configNumberFormatter.integerPercentFormat, ...config })
      : new Intl.NumberFormat("en-NY", { ...configNumberFormatter.integerPercentFormat, ...config }).format(
          val as number
        );
  },

  integerFormat: (val, config: Intl.NumberFormatOptions): string => {
    if (typeof val !== "number" && val !== null) {
      return val;
    }

    return config?.notation === "compact" && IS_CUSTOM_COMPACT
      ? compactFormatter(val, { ...configNumberFormatter.integerFormat, ...config })
      : new Intl.NumberFormat(undefined, { ...configNumberFormatter.integerFormat, ...config }).format(val as number);
  },

  numberFixedFormat: (val, config: Intl.NumberFormatOptions): string => {
    if (typeof val !== "number" && val !== null) {
      return val;
    }

    return config?.notation === "compact" && IS_CUSTOM_COMPACT
      ? compactFormatter(val, { ...configNumberFormatter.numberFixedFormat, ...config })
      : new Intl.NumberFormat(undefined, { ...configNumberFormatter.numberFixedFormat, ...config }).format(
          val as number
        );
  },

  formatDate: (val) => formatDate(val, VISUAL_DATE_FORMAT),
  formatMonth: (val) => formatDate(val, VISUAL_MONTH_FORMAT),
  formatQuarter: (val) => formatDate(val, VISUAL_QUARTER_FORMAT),

  stringFormat(val) {
    if (val === null) {
      return Lang.null;
    }

    return val;
  },
};

export const formatter = (
  val: string | number | null,
  format: string | undefined,
  config?: { [key: string]: string | number }
): string | number | null => {
  if (!format) {
    return val;
  }

  return formatters[format] ? formatters[format](val, config) : val;
};
