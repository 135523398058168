export default {
  logoText: "CYRIS",
  createNewPassword: "Create new password",
  titleResetPassword: "Reset Password",
  signIn: "Sign in",
  forgotPasswordTitle: "Forgot password?",
  accountManagement: "Account Management",
  addNewAccount: "Add New Account",
  editAccount: "Edit Account",
  userManagement: "User Management",
  addNewPage: "Add a New Page",
  widgetsLibrary: "Widgets Library",
  added: "Added",
  presets: "Presets",
  dateRanges: "Date Ranges",
  dateRange: "Date Range",
  chartConfiguration: "Chart Configuration",
  summaryConfiguration: "Summary Configuration",
  chartSettings: "Chart Settings",
  summarySettings: "Summary Settings",
  colorTheme: "Color Theme",
  moreFilters: "More Filters",
  additionalDetails: "Additional Details",
  emailHasBeenSent: "Email has been sent",
  passwordHasBeenSuccessfullyChanged: "Password has been successfully changed",
  tableConfiguration: "Table Configuration",
  showColumns: "Show Columns",
  dimensions: "Dimensions",
  dimensionBased: "Dimension Based",
  standardMetrics: "Standard Metrics",
  countableMetrics: "Countable Metrics",
  selectColumns: "Select Columns",
  familyName: "Family",
  chooseTemplateToUpdate: "Select template to update",
  templateManagement: "Template Management",
  colorPalette: "Color palette",
  accentColor: "Accent Color",
  logoImage: "Logo",
  dropZone: "Drag and drop<br />a picture here",
};
