import { cloneDeep } from "lodash";
import { ActionContext } from "vuex";

import Api from "@/api/Api";
import { UserSettingsConfig, Widget } from "@/classes/UserSettingsManagement";
import Lang from "@/lang";
import router, { userSettingsManagement } from "@/router";
import CleanupService from "@/services/cleanupService";
import { API_ROUTES, requestTokens } from "@/settings/apiRoutes";
import ROUTES from "@/settings/routes";
import store, { RootState } from "@/store";

export const prepareFilterParams = (value: FilterParams) => {
  const catalogs: Record<keyof FilterParams, string[]> = store.getters["catalogs/catalogs"];

  return (Object.keys(value) as (keyof FilterParams)[]).reduce(
    (acc, key) => ({
      ...acc,
      [key]: Array.isArray(value[key])
        ? (value[key] as string[])?.filter((item: string) => catalogs[key as keyof FilterParams]?.includes(item))
        : value[key],
    }),
    {} as FilterParams
  );
};

export interface ContentWidget {
  id: string;
  widget: string;
  title: string;
  chartType: string;
  family: string;
  settings: Record<string, unknown>;
  // These properties should not be persisted in the user settings.
  defaultHeight?: number;
  controls?: Record<string, unknown>;
}

export interface ItemLayout {
  grid: Widget;
  content: ContentWidget;
}

export interface DateRange {
  type?: "basic" | "preset";
  value?: {
    startTime?: string;
    endTime?: string;
    preset?: string;
  };
}

export interface FilterParams {
  agency: string[] | null;
  advertiser: string[] | null;
  businessGoal: string[] | null;
  campaign: string[] | null;
  campaignKpi: string[] | null;
  campaignId: string[] | null;
  campaignBudget: string[] | null;
  channel: string[] | null;
  dateRange: DateRange;
  industry: string[] | null;
  page?: number;
  page_size?: number;
}

export type FilterState = {
  filterParams: FilterParams;
  selectedFilters: string[];
};

export type Layout = ItemLayout[];

export interface Dashboard {
  id: string;
  title: string;
  icon: string;
  layout: Layout;
  filterState: FilterState;
  isDefaultPage?: boolean;
  idTemplate?: string;
}

export interface UserSettings {
  modifiedAt?: number;
  dashboards: Dashboard[];
}

export interface StateUserSettings {
  userSettings: UserSettings;
  filterState: FilterState;
  newPageFilterParams: FilterParams;
  isLoadingUserSettings: boolean;
  settingsFetchError: boolean;
}

export default {
  namespaced: true,

  state: {
    userSettings: {
      dashboards: [],
    },
    filterState: {
      filterParams: {},
      selectedFilters: [],
    },
    newPageFilterParams: {
      dashboards: [],
    },
    isLoadingUserSettings: false,
    settingsFetchError: false,
  },

  mutations: {
    updateLoadingUserSettings(state: StateUserSettings, value: boolean): void {
      state.isLoadingUserSettings = value;
    },

    updateFilterParams(state: StateUserSettings, { value }: { value: FilterParams; dashboardIndex: number }): void {
      state.filterState.filterParams = prepareFilterParams(value);
    },

    updateSelectedFilters(state: StateUserSettings, params: { value: string[]; dashboardIndex: number }): void {
      state.filterState.selectedFilters = [...params.value];
    },

    resetUserSettings(state: StateUserSettings): void {
      state.userSettings = {
        dashboards: [],
      };
    },

    updateNewPageFilterParams(state: StateUserSettings, params: { value: FilterParams }): void {
      state.newPageFilterParams = params.value;
    },

    setDefaultFilterState(state: StateUserSettings, dashboardIndex: number): void {
      const dashboard = state?.userSettings?.dashboards[dashboardIndex];

      if (dashboard) {
        const filterState = cloneDeep(dashboard.filterState);
        filterState.filterParams = prepareFilterParams(filterState.filterParams);
        state.filterState = filterState;
      }
    },

    deleteWidget(state: StateUserSettings, { dashboardId, widgetId }: { widgetId: string; dashboardId: string }) {
      const currentDashboard = state.userSettings.dashboards.find((dashboard) => dashboard.id === dashboardId);

      if (currentDashboard) {
        const widgetIndex = currentDashboard.layout.findIndex((item: ItemLayout) => {
          return item.content.id === widgetId;
        });
        if (widgetIndex !== -1) {
          currentDashboard.layout.splice(widgetIndex, 1);
        }
      }
    },

    updateWidget(
      state: StateUserSettings,
      { dashboardId, widgetId, content }: { widgetId: string; dashboardId: string; content: ContentWidget }
    ) {
      const currentDashboard = state.userSettings.dashboards.find(
        (dashboard) => dashboard.id === dashboardId
      ) as Dashboard;

      if (currentDashboard) {
        const widgetIndex = currentDashboard.layout.findIndex((item: ItemLayout) => {
          return item.content.id === widgetId;
        }) as number;
        if (widgetIndex !== -1) {
          currentDashboard.layout.splice(widgetIndex, 1, {
            grid: currentDashboard.layout[widgetIndex]?.grid,
            content,
          });
        }
      }
    },
  },

  actions: {
    async getSettings(ctx: ActionContext<StateUserSettings, RootState>): Promise<void> {
      ctx.state.isLoadingUserSettings = true;
      ctx.state.settingsFetchError = false;

      const data = await Api.request<UserSettings | undefined>({
        path: API_ROUTES.USER_SETTINGS.PATH,
        defaultResult: undefined,
        errorText: Lang.errorGetUserSettings,
        token: requestTokens.apiGetUserSettings,
        errorCallback: async () => {
          store.commit("app/showAlert", { message: Lang.errorGetUserSettings, type: "error" });
          ctx.state.settingsFetchError = true;
        },
      });

      if (data) {
        if (typeof data === "object") {
          ctx.state.userSettings = data;
        } else {
          store.commit("app/showAlert", { message: Lang.wrongUserSettingsFormat, type: "error" });
          ctx.state.settingsFetchError = true;
        }
      }

      ctx.state.isLoadingUserSettings = false;
    },

    async setDefaultSettings(ctx: ActionContext<StateUserSettings, RootState>, setting: UserSettingsConfig) {
      ctx.state.isLoadingUserSettings = true;

      ctx.state.userSettings = await Api.request<UserSettings>({
        path: API_ROUTES.USER_SETTINGS.PATH,
        defaultResult: {} as UserSettings,
        method: "put",
        body: setting as UserSettingsConfig,
        errorText: Lang.errorSaveUserSettings,
        token: requestTokens.apiSetDefaultUserSettings,
        errorCallback: async () => {
          store.commit("app/showAlert", { message: Lang.errorSaveUserSettings, type: "error" });
          if (router.currentRoute.path !== (ROUTES.LOGIN.PATH || "/")) {
            await router.push(ROUTES.LOGIN.PATH);
          }
        },
      });

      ctx.state.isLoadingUserSettings = false;
    },

    async addNewPage(ctx: ActionContext<StateUserSettings, RootState>, dashboard: Dashboard) {
      if (!ctx.state.userSettings) {
        throw "The userSettings parameter does not exist.";
      }

      if (Array.isArray(ctx.state.userSettings?.dashboards)) {
        const newSettings = {
          ...ctx.state.userSettings,
          modifiedAt: Date.now(),
          dashboards: [...ctx.state.userSettings.dashboards, dashboard],
        };

        const updatedSettings = userSettingsManagement.updateSettings(newSettings);

        updatedSettings.dashboards = updatedSettings.dashboards.map((db) => {
          db.filterState.filterParams = CleanupService.cleanByType(
            db.filterState.filterParams as unknown as Record<string, unknown>
          ) as unknown as FilterParams;
          return db;
        });

        ctx.state.userSettings = await Api.request<UserSettings>({
          path: API_ROUTES.USER_SETTINGS.PATH,
          defaultResult: {} as UserSettings,
          method: "put",
          body: updatedSettings,
          errorText: Lang.errorAddNewPage,
          token: requestTokens.apiAddNewPage,
        });
      }
    },

    async deletePage(ctx: ActionContext<StateUserSettings, RootState>, dashboardId: string): Promise<void> {
      if (!ctx.state.userSettings) {
        throw "The userSettings parameter does not exist.";
      }
      ctx.state.isLoadingUserSettings = true;
      if (Array.isArray(ctx.state.userSettings?.dashboards)) {
        let dashboards = ctx.state.userSettings.dashboards.filter((dashboard) => {
          return dashboard.id !== dashboardId;
        });

        dashboards = dashboards.map((db) => {
          db.filterState.filterParams = CleanupService.cleanByType(
            db.filterState.filterParams as unknown as Record<string, unknown>
          ) as unknown as FilterParams;
          return db;
        });

        const newSettings = {
          ...ctx.state.userSettings,
          modifiedAt: Date.now(),
          dashboards,
        };

        ctx.state.userSettings = await Api.request<UserSettings>({
          path: API_ROUTES.USER_SETTINGS.PATH,
          defaultResult: {} as UserSettings,
          method: "put",
          body: newSettings,
          errorText: Lang.errorAddNewPage,
          token: requestTokens.apiAddNewPage,
        });
      }

      ctx.state.isLoadingUserSettings = false;
    },

    async updatePage(ctx: ActionContext<StateUserSettings, RootState>, dashboard: Dashboard) {
      if (!ctx.state.userSettings) {
        throw "The userSettings parameter does not exist.";
      }
      ctx.state.isLoadingUserSettings = true;
      if (Array.isArray(ctx.state.userSettings?.dashboards)) {
        let dashboards = ctx.state.userSettings.dashboards.map((item: Dashboard) => {
          return item.id !== dashboard.id ? item : dashboard;
        });

        dashboards = dashboards.map((db) => {
          db.filterState.filterParams = CleanupService.cleanByType(
            db.filterState.filterParams as unknown as Record<string, unknown>
          ) as unknown as FilterParams;
          return db;
        });

        const newSettings = {
          ...ctx.state.userSettings,
          modifiedAt: Date.now(),
          dashboards,
        };

        ctx.state.userSettings = await Api.request<UserSettings>({
          path: API_ROUTES.USER_SETTINGS.PATH,
          defaultResult: newSettings,
          method: "put",
          body: newSettings,
          errorText: Lang.errorUpdatePage,
          token: requestTokens.apiUpdatePage,
        });
      }
      ctx.state.isLoadingUserSettings = false;
    },

    async deleteSettings(ctx: ActionContext<StateUserSettings, RootState>) {
      await Api.request({
        path: API_ROUTES.USER_SETTINGS.PATH,
        method: "del",
        defaultResult: undefined,
        successCallback: () => {
          ctx.state.userSettings = {
            dashboards: [],
            modifiedAt: undefined,
          };
        },
        errorText: Lang.errorDeleteSettings,
        token: requestTokens.apiDeleteUserSettings,
      });
    },
  },

  getters: {
    userSettings(state: StateUserSettings): UserSettings | null {
      return state.userSettings;
    },

    dashboards(state: StateUserSettings): Dashboard[] {
      return state.userSettings?.dashboards || [];
    },

    filterParams(state: StateUserSettings): FilterParams | null {
      return state?.filterState?.filterParams;
    },

    newPageFilterParams(state: StateUserSettings): FilterParams {
      return state.newPageFilterParams;
    },

    selectedFilters(state: StateUserSettings): string[] {
      return state?.filterState?.selectedFilters || [];
    },

    isLoadingUserSettings(state: StateUserSettings): boolean {
      return state.isLoadingUserSettings;
    },

    settingsFetchError(state: StateUserSettings): boolean {
      return state.settingsFetchError;
    },
  },
};
