import {
  compactFormat,
  dimension,
  dimensionSelector,
  legendPosition,
  palette,
  primaryMetric,
  primaryMetricSelector,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.DECOMPOSITION],
  draft: [],
  title: "Heat Map",
  defaultHeight: 6,
  controls: [dimension, primaryMetric],

  settings: {
    palette,
    legendPosition: { ...legendPosition, gridPosition: [3, 0] },
    compactFormat: { ...compactFormat, gridPosition: [4, 0] },
    // showItems: { ...showItems, gridPosition: [2, 1] },
    // TODO: Maybe show items will appear here later.

    decompositionMetric: {
      default: "channel",
      type: "select",
      label: "Decomposition Metric 1",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    decompositionMetricSecondary: {
      default: "campaign",
      type: "select",
      label: "Decomposition Metric 2",
      dictionary: "dimensionMetrics",
      apiParameter: "dimension",
      gridPosition: [1, 0],
      tooltipOrder: 0,
    },
    decompositionMetricTitle: {
      default: "",
      type: "text",
      label: "Decomposition Metric 1 Axis Title",
      gridPosition: [0, 1],
      forAxis: "decompositionMetric",
    },
    decompositionMetricSecondaryTitle: {
      default: "",
      type: "text",
      label: "Decomposition Metric 2 Axis Title",
      gridPosition: [1, 1],
      forAxis: "decompositionMetricSecondary",
    },
    displayMetric: {
      default: "spend",
      type: "select",
      label: "Display Metric",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
      tooltipOrder: 1,
    },
    minValueColor: {
      default: "#4E5068",
      type: "color",
      label: "Min Value Color",
      dictionary: "colors",
      gridPosition: [3, 1],
    },
    maxValueColor: {
      default: "#DFEBF0",
      type: "color",
      label: "Max Value Color",
      dictionary: "colors",
      gridPosition: [4, 1],
    },
    dimensionSelector: { ...dimensionSelector, gridPosition: [5, 0] },
    primaryMetricSelector: { ...primaryMetricSelector, gridPosition: [6, 0] },
  },
};
