export const VALIDATORS = {
  REQUIRED: (value: string): boolean | string => {
    return (!!value && !!value.trim()) || "Field is required.";
  },
  REQUIRED_CHECKBOX: (value: boolean): boolean | string => {
    return !value || value || "Field is required.";
  },
  REQUIRED_COMPANY_NAME: (value: string): boolean | string => {
    return (!!value && !!value.trim()) || "Company name is required.";
  },
  REQUIRED_CODE: (value: string): boolean | string => {
    return (!!value && !!value.trim()) || "Code is required.";
  },
  REQUIRED_ACCOUNT: (value: string): boolean | string => {
    return (!!value && !!Number(value)) || "Account is required.";
  },
  REQUIRED_ACCOUNTS: (value: string): boolean | string => {
    return (!!value && !!value.length) || "Accounts is required.";
  },
  REQUIRED_PASSWORD: (value: string): boolean | string => {
    if (!value) {
      return "Password is required.";
    }

    if (value.length < 8) {
      return "Password must be at least 8 characters";
    }

    if (!/[a-z]/.test(value)) {
      return "Password must have at least 1 lower case character";
    }

    if (!/.*[A-Z].*/.test(value)) {
      return "Password must have at least 1 upper case character";
    }

    if (!/.*[0-9].*/.test(value)) {
      return "Password must have at least 1 numerical character";
    }

    if (!/.*[^a-zA-Z0-9].*/.test(value)) {
      return "Password must have at least 1 symbol";
    }

    return true;
  },
  REQUIRED_PASSWORD_LOGIN: (value: string): boolean | string => !!value || "Password is required.",
  REQUIRED_PASSWORD_CONFIRMATION: (value: string): boolean | string => !!value || "Password confirmation is required.",
  REQUIRED_EMAIL: (value: string): boolean | string => {
    if (!value) {
      return "E-mail is required.";
    }

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toLowerCase()) || "Must be valid email address.";
  },
};
