import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { aclSettings } from "@/acl/aclSettings";
import CurrentAcl from "@/acl/CurrentAcl";
import UserSettingsManagement, { DashboardConfig } from "@/classes/UserSettingsManagement";
import WidgetApi from "@/classes/WidgetApi";
import ROUTES, { NO_AUTH_PATHS } from "@/settings/routes";
import { USER_SETTINGS_VERSION } from "@/settings/userDefaultSettings";
import store from "@/store";

const Dashboard = () => import("@/views/Dashboard.vue");
const Grid = () => import("@/components/Grid.vue");
const Login = () => import("@/components/auth/Login.vue");
const RestorePassword = () => import("@/components/auth/RestorePassword.vue");
const ConfirmNewPassword = () => import("@/components/auth/ConfirmNewPassword.vue");
const ResetPassword = () => import("@/components/auth/ResetPassword.vue");
const AccountManagement = () => import("@/views/AccountManagement.vue");
const UserManagement = () => import("@/views/UserManagement.vue");
const Platform = () => import("@/views/Platform.vue");
const Settings = () => import("@/views/Settings.vue");
const PageCreate = () => import("@/views/PageCreate.vue");
const PageEdit = () => import("@/views/PageEdit.vue");
const AdditionalDetailsDrawer = () => import("@/components/drawers/AdditionalDetailsDrawer.vue");
const TemplateManagement = () => import("@/views/TemplateManagement.vue");
const Glossary = () => import("@/views/GlossaryPage.vue");

export const routes: Array<RouteConfig> = [
  {
    path: ROUTES.LOGIN.PATH,
    component: Login,
  },
  {
    path: ROUTES.RESTORE_PASSWORD.PATH,
    name: ROUTES.RESTORE_PASSWORD.NAME,
    component: RestorePassword,
  },
  {
    path: ROUTES.CONFIRM_NEW_PASSWORD.PATH,
    name: ROUTES.CONFIRM_NEW_PASSWORD.NAME,
    component: ConfirmNewPassword,
  },
  {
    path: ROUTES.RESET_PASSWORD.PATH,
    name: ROUTES.RESET_PASSWORD.NAME,
    component: ResetPassword,
  },
  {
    path: ROUTES.ACCOUNT_MANAGEMENT.PATH,
    name: ROUTES.ACCOUNT_MANAGEMENT.NAME,
    component: AccountManagement,
  },
  {
    path: ROUTES.USER_MANAGEMENT.PATH,
    name: ROUTES.USER_MANAGEMENT.NAME,
    component: UserManagement,
  },
  {
    path: ROUTES.PLATFORM.PATH,
    name: ROUTES.PLATFORM.NAME,
    component: Platform,
  },
  {
    path: ROUTES.SETTINGS.PATH,
    name: ROUTES.SETTINGS.NAME,
    component: Settings,
  },
  {
    path: ROUTES.DASHBOARD.PATH,
    component: Dashboard,
    children: [
      {
        path: ":dashboardName",
        name: ROUTES.DASHBOARD.NAME,
        component: Grid,
        props: true,
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND.PATH,
    name: ROUTES.NOT_FOUND.NAME,
    redirect: ROUTES.DASHBOARD.PATH,
  },
  {
    path: ROUTES.CREATE_NEW_PAGE.PATH,
    name: ROUTES.CREATE_NEW_PAGE.NAME,
    component: PageCreate,
  },
  {
    path: ROUTES.ADDITIONAL_DETAILS.PATH,
    name: ROUTES.ADDITIONAL_DETAILS.NAME,
    component: AdditionalDetailsDrawer,
  },
  {
    path: ROUTES.TEMPLATE_MANAGEMENT.PATH,
    name: ROUTES.TEMPLATE_MANAGEMENT.NAME,
    component: TemplateManagement,
  },
  {
    path: ROUTES.EDIT_PAGE.PATH,
    component: PageEdit,
    children: [
      {
        path: ":dashboardName",
        name: ROUTES.EDIT_PAGE.NAME,
        component: Grid,
        props: { edit: true },
      },
    ],
  },
  {
    path: ROUTES.GLOSSARY.PATH,
    name: ROUTES.GLOSSARY.NAME,
    component: Glossary,
  },
  {
    path: "*",
    redirect: ROUTES.DASHBOARD.PATH,
  },
];

Vue.use(VueRouter);

export const acl = new CurrentAcl();
export const userSettingsManagement = new UserSettingsManagement();
export const widgetApi = new WidgetApi();

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

let areUserSettingsReceived = false;

router.beforeEach(async (to, from, next) => {
  if (NO_AUTH_PATHS.includes(to.path)) {
    return next();
  }

  await store.dispatch("auth/fetchUser");

  const isAuthorized = store.getters["auth/isAuthorized"];
  let userRole = store.getters["auth/userRole"];

  if (isAuthorized && !userRole) {
    await store.dispatch("auth/fetchUser");
    userRole = store.getters["auth/userRole"];
  }

  if (isAuthorized && aclSettings[userRole]) {
    if (!store.getters["userSettings/userSettings"] || !store.getters["catalogs/catalogs"]) {
      try {
        await Promise.all([store.dispatch("userSettings/getSettings"), store.dispatch("catalogs/fetchCatalogs")]);
        areUserSettingsReceived = true;
      } catch (e) {
        areUserSettingsReceived = false;
      }
    }

    if (to.path == from.path && !areUserSettingsReceived) {
      return;
    }

    // Checking if the settings are too old, and remove them if so.
    const isNotOldSettings = store.getters["userSettings/userSettings"]?.dashboards.find(
      (dashboard: DashboardConfig) => {
        return dashboard.idTemplate;
      }
    );

    if (!store.getters["userSettings/userSettings"]?.modifiedAt || !isNotOldSettings) {
      if (store.getters["userSettings/settingsFetchError"]) {
        try {
          await store.dispatch("auth/userLogout");
        } catch (e) {
          console.error("Error signing out: ", e);
        }

        return next(ROUTES.LOGIN.PATH);
      }
      await store.dispatch("userSettings/setDefaultSettings", userSettingsManagement.createDefaultSettings());
    }

    const userSettingsVersion = store.getters["userSettings/userSettings"]?.userSettingsVersion || "0";
    if (userSettingsManagement.compareVersion(userSettingsVersion, USER_SETTINGS_VERSION)) {
      const preparedSettings = userSettingsManagement.updateSettings(store.getters["userSettings/userSettings"]);
      await store.dispatch("userSettings/setDefaultSettings", preparedSettings);
      return next("/");
    }

    router.app.$vuetify.theme.dark = store.getters["userSettings/userSettings"]?.theme === "dark";
    return acl.processRoute({ to, from, next }, aclSettings[userRole]);
  } else {
    next(ROUTES.LOGIN.PATH);
  }
});

export default router;
