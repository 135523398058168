import {
  compactFormat,
  granularity,
  legendPosition,
  lineThickness,
  palette,
} from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OVERTIME],
  recommendedFamily: [FAMILY.OVERTIME],
  name: "lineChart",
  title: "Line Chart",
  defaultHeight: 6,
  controls: [granularity],
  settings: {
    palette,
    lineThickness: { ...lineThickness, gridPosition: [3, 0] },
    legendPosition: { ...legendPosition, gridPosition: [4, 0] },
    compactFormat: { ...compactFormat, gridPosition: [5, 0] },
    xAxis: {
      default: "month",
      type: "select",
      label: "Horizontal Axis Metric",
      dictionary: "timeMetrics",
      apiParameter: "dimension",
      gridPosition: [0, 0],
      tooltipOrder: 0,
    },
    yAxisPrimary: {
      default: "spend",
      type: "select",
      label: "Primary Vertical Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
      tooltipOrder: 1,
    },
    yAxisSecondary: {
      default: "impressions",
      type: "select",
      label: "Secondary Vertical Axis Metric",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
      tooltipOrder: 2,
    },
    xAxisTitle: {
      default: "",
      type: "text",
      label: "Horizontal Axis Title",
      gridPosition: [0, 1],
      forAxis: "xAxis",
    },
    yAxisPrimaryTitle: {
      default: "",
      type: "text",
      label: "Primary Vertical Axis Title",
      gridPosition: [1, 1],
      forAxis: "yAxisPrimary",
    },
    yAxisSecondaryTitle: {
      default: "",
      type: "text",
      label: "Secondary Vertical Axis Title",
      gridPosition: [2, 1],
      forAxis: "yAxisSecondary",
    },
  },
};
