import { forIn } from "lodash";

import inputsDictionary from "@/settings/inputsDictionary";

export const getCountableMetrics = (metrics: Array<string>): Array<string> | undefined => {
  const countableMetrics: Array<string> = [];

  forIn(metrics, (setting) => {
    const metric = inputsDictionary.displayMetrics.find((metric) => {
      return setting === metric.value && metric.isCountableMetric;
    });

    if (metric) {
      countableMetrics.push(metric.value as string);
    }
  });

  return countableMetrics.length ? countableMetrics : undefined;
};

export const getDimensionMetrics = (metrics: Array<string>): Array<string> | undefined => {
  const dimensionMetrics: Array<string> = [];
  const allDimensions = [...inputsDictionary.dimensionMetrics, ...inputsDictionary.timeMetrics];

  forIn(metrics, (setting) => {
    const metric = allDimensions.find((metric) => setting === metric.value);

    if (metric) {
      dimensionMetrics.push(metric.value as string);
    }
  });
  return dimensionMetrics.length ? dimensionMetrics : undefined;
};
