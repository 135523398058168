export default {
  accountDialog: {
    enable: {
      title: "Do you want to enable this account?",
      message: "All users associated with this account will be able to log into the system!",
      cancel: "Keep Account Disabled",
      confirm: "Enable Account",
    },
    disable: {
      title: "Do you want to disable this account?",
      message: "All users associated with this account will not be able to log into the system!",
      cancel: "Keep Account Enabled",
      confirm: "Disable Account",
    },
  },
  removeAccountDialog: {
    title: "Do you want to delete this account?",
    message:
      "Once you confirm, all of your account data will be permanently deleted. Account deletion is final. There will be no way to restore the account.",
    cancel: "Cancel",
    confirm: "Delete Account",
  },

  userDialog: {
    enable: {
      title: "Do you want to enable this user?",
      message: "The user will be able to log into the system!",
      cancel: "Keep User Disabled",
      confirm: "Enable User",
    },
    disable: {
      title: "Do you want to disable this user?",
      message: "The user will not be able to log into the system!",
      cancel: "Keep User Enabled",
      confirm: "Disable User",
    },
  },

  userTemplate: {
    select: {
      title: "Do you want to select this template?",
      confirm: "Select Template",
      isConfirmation: true,
      danger: true,
    },
    delete: {
      cancel: "Cancel",
      confirm: "Delete",
      isConfirmation: true,
      danger: true,
    },
    titleDelete: (templateName: string) => {
      return `Do you want to delete the template ${templateName}?`;
    },
  },

  removeUserDialog: {
    title: "Do you want to delete this user?",
    message:
      "Once you confirm, all of your user data will be permanently deleted. User deletion is final. There will be no way to restore the user.",
    cancel: "Cancel",
    confirm: "Delete User",
  },

  goBackSaveDialog: {
    title: "Not all the changes have been saved",
    cancel: "Discard the changes",
    confirm: "Save and go to the page",
  },

  deletePageDialog: {
    title: "The current dashboard will be removed",
    cancel: "Cancel",
    confirm: "Remove",
  },

  resetPageDialog: {
    title: "The current dashboard will be reset to initial state",
    cancel: "Cancel",
    confirm: "Reset",
  },

  confirmTemplateDialog: {
    title: (templateName: string) => `Do you want to update template "${templateName}"?`,
    cancel: "Cancel",
    confirm: "Update",
  },
};
