import { compactFormat, granularity, legendPosition, palette } from "@/settings/charts/settingsDictionary";
import { FAMILY } from "@/settings/widgetFamilies";
import { Config } from "@/types/charts";

export default <Config>{
  family: [FAMILY.OTHER],
  title: "Funnel Chart",
  defaultHeight: 6,
  controls: [granularity],
  settings: {
    palette,
    legendPosition: { ...legendPosition, gridPosition: [0, 1] },
    compactFormat: { ...compactFormat, gridPosition: [1, 1] },

    stage1Metric: {
      default: "impressions",
      type: "select",
      label: "Stage Metric 1",
      dictionary: "displayMetrics",
      gridPosition: [0, 0],
    },
    stage2Metric: {
      default: "clicks",
      type: "select",
      label: "Stage Metric 2",
      dictionary: "displayMetrics",
      gridPosition: [1, 0],
    },
    stage3Metric: {
      default: "app_installs",
      type: "select",
      label: "Stage Metric 3",
      dictionary: "displayMetrics",
      gridPosition: [2, 0],
    },
    stage4Metric: {
      default: null,
      type: "select",
      label: "Stage Metric 4",
      dictionary: "nullableDisplayMetrics",
      gridPosition: [3, 0],
    },
    stage5Metric: {
      default: null,
      type: "select",
      label: "Stage Metric 5",
      dictionary: "nullableDisplayMetrics",
      gridPosition: [4, 0],
      dependencies: [
        {
          names: ["stage4Metric"],
          type: "disable",
          rule: (dependValues: string[]): boolean => {
            const [dependVal] = dependValues;
            return !dependVal;
          },
          newValues: [
            {
              propName: "stage5Metric",
              condition: (ruleResult: boolean): boolean => {
                return ruleResult;
              },
              value: null,
            },
          ],
        },
      ],
    },
  },
};
