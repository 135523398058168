import { FAMILY } from "@/settings/widgetFamilies";

export type TrendColor = "positive" | "negative" | "default" | "loading";
export type TrendDirection = "up" | "down" | "same";

type Badge = {
  direction?: TrendDirection;
  color?: TrendColor;
  isShown: boolean;
};

export interface SummaryItem {
  name: string;
  value: number | string | null;
  badge?: Badge;
}

export const trendDirections: Record<TrendDirection, TrendDirection> = {
  up: "up",
  down: "down",
  same: "same",
};

export const trendColors: Record<TrendColor, TrendColor> = {
  positive: "positive",
  negative: "negative",
  default: "default",
  loading: "loading",
};

export default {
  family: [FAMILY.OTHER],
  recommendedFamily: [FAMILY.OTHER],
  title: "Summary",
  defaultHeight: 4,
  widgetSettings: {
    widget: "SummaryWidget",
  },
  settings: {
    metrics: {
      default: [
        "advertisers",
        "spend",
        "impressions",
        "clicks",
        "video_views",
        "completed_views",
        "cpm",
        "ctr",
        "cpc",
        "vcr",
      ],
    },
    trendCalculation: {
      default: "pop",
      type: "select",
      label: "Trend Calculation",
      gridPosition: [0, 0],
      dictionary: "summaryTrendOptions",
    },
    showTrends: {
      default: true,
      type: "checkbox",
      label: "Show badges",
      gridPosition: [1, 0],
    },
    compactFormat: {
      default: false,
      type: "checkbox",
      label: "Compact Form",
      gridPosition: [2, 0],
    },
  },
};
