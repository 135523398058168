import { formatters } from "@/helpers/formatters";
import { InputDictionary } from "@/types/common";

export default <InputDictionary[]>[
  {
    title: "Advertiser",
    value: "advertiser",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Agency",
    value: "agency",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Business Goal",
    value: "business_goal",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Campaign",
    value: "campaign",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Campaign KPI",
    value: "campaign_kpi",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Campaign ID",
    value: "campaign_id",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Channel",
    value: "channel",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Channel Detail",
    value: "channel_detail",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Channel Objective",
    value: "channel_objective",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "DSP",
    value: "dsp",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Industry",
    value: "industry",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Industry Target",
    value: "industry_target",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Media Type",
    value: "media_type",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Planning Stage",
    value: "planning_stage",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
  {
    title: "Sub-industry",
    value: "sub_industry",
    formatter: formatters.stringFormat,
    table: { group: "dimensions" },
  },
];
