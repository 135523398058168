export default {
  pleaseEnterEmail: "Please, enter the email you use to sign in.",
  pleaseCheckInboxAndClickLink: "Please, check your inbox and click in the received link to reset a password.",
  didYouReceiveTheLink: "Didn't receive the link?",
  linkHasBeenResent: "The link has been resent!",
  pleaseEnterNewPassword: "Please, enter your new password.",
  enterCodeAndNewPassword: "Please, enter the code from received e-mail and new password.",
  signInWithNewPassword: "Please, sign in with new password.",
  mailShouldBeRegistered: "E-mail should be registered in the system.",
  instructionsWillBeSent: "Further instructions will be sent to your email.",
  passwordMust: "Password Must",
  passwordRules: [
    "Be at least 8 characters.",
    "Have at least 1 upper case character.",
    "Have at least 1 numerical character.",
    "Have at least 1 symbol.",
  ],
  defaultAlertMessage: {
    error: "Unknown error occurred.",
    info: "Success.",
    success: "Success.",
  },
  noData: "Empty data",
  noChart: "Unknown chart type, please re-create the chart.",
  errorGetData: "Error get data.",
  errorGetFiltersData: "Error get filters data.",
  errorGetSummaryData: "Error get summary data.",
  errorGetTableData: "Error get table data.",
  errorGetFormData: "Error get form data.",
  errorGetTemplates: "Error get templates",
  errorCreateTemplate: "Error create new template.",
  errorUpdateTemplate: "Error update template.",
  errorCreateTenant: "Error create tenant.",
  errorEditTenant: "Error edit tenant.",
  errorGetCatalogs: "Error get catalogs.",
  errorGetUserSettings: "Error get user settings.",
  errorSaveUserSettings: "Error save user settings.",
  errorAddNewPage: "Error add new page.",
  errorUpdatePage: "Error update page.",
  errorDeleteSettings: "Error delete settings.",
  errorGetTenants: "Error get tenants.",
  errorDisableEnableTenant: "Error disable/enable tenant.",
  errorGetAgencies: "Error get agencies.",
  errorGetTenant: "Error get tenant.",
  errorGetUsers: "Error get users.",
  errorEnableUser: "Error enable user.",
  errorDisableUser: "Error disable user.",
  errorSaveUser: "Error save user.",
  errorUpdateUser: "Error update user.",
  errorResetPassword: "Error send invite.",
  errorRestorePasswordAttemptsLimitExceeded:
    "Password restore attempts limit exceeded, please try again after some time.",
  errorRestorePasswordUnexpected: "Unexpected error when try to restore password.",
  errorInvalidEmailOrPassword: "Invalid email or password.",
  dashboardCreated: (dashboardName: string) => {
    return `Dashboard "${dashboardName}" was successfully created.`;
  },
  dashboardNotCreated: (dashboardName: string) => {
    return `Error occurred when dashboard "${dashboardName}" was created.`;
  },
  dashboardUpdated: (dashboardName: string) => {
    return `Dashboard "${dashboardName}" was successfully updated.`;
  },
  dashboardReset: (dashboardName: string) => {
    return `Dashboard "${dashboardName}" was successfully reset to initial state.`;
  },
  dashboardNotReset: (dashboardName: string) => {
    return `Error occurred when dashboard "${dashboardName}" was reset to initial state.`;
  },
  dashboardDeleted: (dashboardName: string) => {
    return `Dashboard "${dashboardName}" was successfully removed.`;
  },
  dashboardNotDeleted: (dashboardName: string) => {
    return `Error occurred when dashboard "${dashboardName}" was removed.`;
  },
  dashboardNotUpdated: (dashboardName: string) => {
    return `Error occurred when dashboard "${dashboardName}" was updated.`;
  },
  userAlreadyExists: "User already exists.",
  emptyTenantsList: "Empty tenants list.",
  successCreateTemplate: "Template created successfully.",
  successUpdateTemplate: "Template updated successfully.",
  successPasswordReset: (email: string) => `Invite was successfully resent to ${email}.`,
  successUserCreated: (email: string) => `User with email ${email} created successfully.`,
  successUserUpdated: (email: string) => `User with email ${email} updated successfully.`,
  successTenantCreated: (name: string) => `Tenant ${name} created successfully.`,
  successTemplateDeleted: (name: string) => `Template ${name} successfully deleted.`,
  successTenantUpdated: (name: string) => `Tenant ${name} updated successfully.`,
  successTenantEnabled: "Tenant enabled successfully.",
  successTenantDisabled: "Tenant disabled successfully.",
  successUserEnabled: "User enabled successfully.",
  successUserDisabled: "User disabled successfully.",
  unknownError: "Unknown error",
  notFound: "Not found",
  errorDeleteTemplate: "Error delete template.",
  imageMessageError: "Please select an image file.",
  wrongUserSettingsFormat: "Wrong user settings format.",
};
