import { formatters } from "@/helpers/formatters";
import { InputDictionary } from "@/types/common";

export default <InputDictionary[]>[
  {
    title: "Date",
    value: "day",
    formatter: formatters.formatDate,
    table: { group: "dimensions", order: 10 },
  },
  {
    title: "Month",
    value: "month",
    formatter: formatters.formatMonth,
  },
  {
    title: "Quarter",
    value: "quarter",
    formatter: formatters.formatQuarter,
  },
];
