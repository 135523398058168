import { cloneDeep, forIn } from "lodash";

import Api from "@/api/Api";
import BaseChart from "@/classes/BaseChart";
import { getCountableMetrics, getDimensionMetrics } from "@/helpers/api";
import { setDate } from "@/helpers/dateRangePicker";
import Lang from "@/lang";
import { API_ROUTES, requestTokens } from "@/settings/apiRoutes";
import { getChartAllSettings } from "@/settings/charts";
import { campaignBudget, dashboardFilters } from "@/settings/dashboardFilters";
import timeMetrics from "@/settings/metrics/timeMetrics";
import store from "@/store";
import { FilterParams } from "@/store/modules/userSettings";
import { ChartDataRecord } from "@/types/charts";

interface TableRequest {
  data?: Array<Record<string, unknown>>;
  total?: number;
  page?: number;
  next_page?: number;
  page_size?: number;
}

interface ChartApiParams {
  [key: string]: string[] | unknown;
  top?: Record<string, string>;
}

type SummaryDataItem = Record<string, number | null>;
export type NormalizedFilterParams = Record<string, Array<string> | string | Record<string, number | string>>;

export default class WidgetApi {
  async getSummaryData(metrics: Array<string>, filter?: FilterParams): Promise<SummaryDataItem[] | undefined> {
    const body = this.getSummaryRequestBody(metrics, filter);

    const res = await Api.request<{ data: SummaryDataItem[] }>({
      path: API_ROUTES.REPORT.PATH,
      defaultResult: { data: [] },
      method: "post",
      body,
      errorText: Lang.errorGetSummaryData,
      token: requestTokens.apiGetSummary,
    });

    return res.data as SummaryDataItem[];
  }

  getSummaryRequestBody(metrics: Array<string>, filter?: FilterParams) {
    const apiParams: Record<string, unknown> = {};
    const filterParams = filter ? this.getFilterParams(filter) : this.getFilterParams();
    const countableMetrics = getCountableMetrics(metrics);
    if (countableMetrics) {
      apiParams.count_metrics = countableMetrics;
    }
    return {
      ...filterParams,
      ...apiParams,
    };
  }

  async getChartData(
    ctx: BaseChart,
    filterParamsTransformer?: (params: NormalizedFilterParams) => NormalizedFilterParams
  ): Promise<ChartDataRecord[]> {
    const body = this.getChartRequestBody(ctx, filterParamsTransformer);
    const res = await Api.request<{ data: ChartDataRecord[] }>({
      path: API_ROUTES.REPORT.PATH,
      defaultResult: { data: [] },
      method: "post",
      body,
      noDataItem: { widgetId: ctx.widget.id, status: true },
      errorText: Lang.errorGetData,
      token: requestTokens.apiGetChartData,
    });

    return res.data;
  }

  getChartRequestBody(
    ctx: BaseChart,
    filterParamsTransformer?: (params: NormalizedFilterParams) => NormalizedFilterParams
  ) {
    const filterParams = filterParamsTransformer
      ? filterParamsTransformer(this.getFilterParams())
      : this.getFilterParams();
    return {
      ...filterParams,
      ...this.buildChartApiParams(ctx),
    };
  }

  getFilterParams(filterParams = store.getters["userSettings/filterParams"]): NormalizedFilterParams {
    const normalizedParams = {} as NormalizedFilterParams;
    for (const param in filterParams) {
      if (Array.isArray(filterParams[param]) && !filterParams[param].includes("All")) {
        const apiKey = dashboardFilters.find((filter) => {
          return filter.filterKey === param;
        })?.apiKey;

        if (apiKey) {
          normalizedParams[apiKey] = cloneDeep(filterParams[param]);
        }
      }
    }

    if (filterParams.dateRange.type === "basic") {
      normalizedParams.start_time = filterParams.dateRange.value?.startTime;
      normalizedParams.end_time = filterParams.dateRange.value?.endTime;
    } else {
      const { startDate, endDate } = setDate(filterParams.dateRange.value?.preset as string);
      if (startDate && endDate) {
        normalizedParams.start_time = startDate;
        normalizedParams.end_time = endDate;
      }
    }

    if (filterParams.campaignBudget && !filterParams.campaignBudget.includes(Lang.all)) {
      normalizedParams.budgets = filterParams.campaignBudget
        .map((item: string) => {
          return campaignBudget.find((campaign) => campaign.text === item)?.value;
        })
        .filter((item: Record<string, number> | undefined) => !!item);
    }

    return normalizedParams;
  }

  private buildChartApiParams(ctx: BaseChart): ChartApiParams {
    const apiParams = {} as ChartApiParams;
    const allSettings = getChartAllSettings(ctx.chartName, ctx.chartFamily);
    const showItems = ctx.settings?.showItems || null;
    let dimension = "";
    let metric = "";

    forIn(allSettings, (setting: Record<string, string | number | number[]>, key: string) => {
      if (setting.dictionary === "displayMetrics") {
        // TODO: Refactoring is needed here. After renaming.
        if (["xAxis", "yAxis", "xAxisPrimary", "yAxisPrimary", "displayMetric"].includes(key)) {
          metric = ctx.settings[key];
        }
      }

      if (setting.apiParameter) {
        const value = ctx.settings[key];
        const decompositionMetric = key === "decompositionMetricTop" ? "decompositionMetricTop" : "decompositionMetric";

        // TODO: Refactoring is needed here. After renaming.
        if (["yAxis", "xAxis", "xAxisPrimary", "yAxisPrimary", decompositionMetric].includes(key)) {
          dimension = value;
        }

        if (Array.isArray(apiParams[setting.apiParameter as string])) {
          (apiParams[setting.apiParameter as string] as string[]).push(value);
        } else {
          apiParams[setting.apiParameter as string] = [value];
        }
      }
    });

    // TODO: Refactor all the above and this condition.
    if (showItems && dimension && !timeMetrics.find((item) => item.value === dimension)) {
      apiParams.top = {
        count: showItems,
        dimension,
        metric,
      };
    }

    const countableMetrics = getCountableMetrics(Object.values(ctx.settings));

    if (countableMetrics) {
      apiParams.count_metrics = countableMetrics;
    }

    return apiParams;
  }

  async getTableData(filter: FilterParams, selectedMetrics: string[]): Promise<TableRequest | undefined> {
    const body = this.getTableRequestBody(filter, selectedMetrics);

    return await Api.request<TableRequest>({
      path: API_ROUTES.REPORT.PATH,
      defaultResult: { data: [] },
      method: "post",
      body,
      errorText: Lang.errorGetTableData,
      token: requestTokens.apiGetTableData,
    });
  }

  getTableRequestBody(filter: FilterParams, selectedMetrics: string[]) {
    const apiParams: Record<string, unknown> = {};
    const filterParams = filter ? this.getFilterParams(filter) : this.getFilterParams();
    const countableMetrics = getCountableMetrics(selectedMetrics);

    if (countableMetrics) {
      apiParams.count_metrics = countableMetrics;
    }

    apiParams.dimension = getDimensionMetrics(selectedMetrics);

    return { ...filterParams, ...apiParams };
  }
}
