export const tableRows = [
  { title: "25", value: 25 },
  { title: "50", value: 50 },
  { title: "100", value: 100 },
  { title: "All", value: -1 },
];

export const defaultTableHeaders = [
  { title: "Campaign", value: "campaign" },
  { title: "Business Goal", value: "business_goal" },
  { title: "Channel", value: "channel" },
  { title: "Spend", value: "spend" },
  { title: "Impressions", value: "impressions" },
];

export interface SortType {
  sortBy: string;
  sortDesc: boolean;
}

export const itemsPerPageVariants = [15, 25, 50, 100, -1];
